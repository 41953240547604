import React from 'react';
import './SessionButton.scss';
import { GradientIconTypes } from '../GradientIcon';
import { FaPlus } from 'react-icons/fa';

export enum SessionButtonTypes {
    COLORED = 'colored',
    GRAY = 'gray',
}

export interface SessionButtonProps {
    type: GradientIconTypes;
    icon?: React.ReactNode;
    isActive?: boolean;
    shadow?: boolean;
    tooltipText?: string;
    tooltipKey?: string;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    children?: React.ReactNode;
}

const SessionButton = ({
    type,
    onClick,
    icon,
    isActive,
    tooltipText,
    tooltipKey,
    children,
    className,
    shadow,
}: SessionButtonProps) => {
    return (
        <div
            className={`session-button-circle-wrapper cursor-pointer ${type} ${isActive ? '' : 'inactive'} ${
                className ? className : ''
            } ${shadow ? 'session-button-shadow' : ''}`}
            onClick={onClick ? onClick : () => {}}
        >
            <div className="session-button-circle">
                <div className="background-circle">
                    <div className="mob-new-session">
                        <FaPlus />
                        Start new session
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SessionButton;
