import React, { useState } from 'react';
import { AssitantIcon, AtTheRate, FlagIcon, HelpOpen, Logout } from '../../../assets/img';

import './Dropdowns.scss';

export const HelpDropdown = ({ onFeedback }: { onFeedback: () => void }) => {
    const [isHover, setIsHover] = useState<boolean>(false);
    const [isSecondHover, setIsSecondHover] = useState<boolean>(false);
    const [isTopHover, setIsTopHover] = useState<boolean>(false);
    const handleContact = () => {
        window.location.href = 'https://ethz.ch/de/utils/contact.html';
    };
    return (
        <div className="help-dropdown">
            <div
                className="account-dropdown-item"
                onMouseOver={() => setIsTopHover(true)}
                onMouseOut={() => setIsTopHover(false)}
            >
                <AssitantIcon />
                <div className="help-text">Launch guided tour</div>
            </div>
            <div
                className="dropdown-seperator-black"
                style={{ backgroundColor: isTopHover ? 'rgb(34, 34, 34)' : 'rgba(0, 0, 0, 0.4)' }}
            />
            <div
                className="dropdown-seperator"
                style={{ backgroundColor: isHover || isTopHover ? 'rgb(76, 76, 76)' : 'rgba(255, 255, 255, 0.1)' }}
            />
            <div
                className="account-dropdown-item"
                onClick={onFeedback}
                onMouseOver={() => setIsHover(true)}
                onMouseOut={() => setIsHover(false)}
            >
                <FlagIcon />
                <div className="help-text">Feedback & bugs</div>
            </div>
            <div
                className="dropdown-seperator-black"
                style={{ backgroundColor: isHover ? 'rgb(34, 34, 34)' : 'rgba(0, 0, 0, 0.4)' }}
            />
            <div
                className="dropdown-seperator"
                style={{ backgroundColor: isSecondHover || isHover ? 'rgb(76, 76, 76)' : 'rgba(255, 255, 255, 0.1)' }}
            />
            <div
                className="account-dropdown-item dropdown-contact"
                onClick={handleContact}
                onMouseOver={() => setIsSecondHover(true)}
                onMouseOut={() => setIsSecondHover(false)}
            >
                <div className="contact-item">
                    <AtTheRate />
                    <div className="help-text">Contact ETH</div>
                </div>
                <HelpOpen />
            </div>
        </div>
    );
};

export const AccountDropdown = ({
    name,
    username,
    handleLogout,
}: {
    name: string;
    username: string;
    handleLogout: () => void;
}) => {
    const [isHover, setIsHover] = useState<boolean>(false);
    return (
        <div className="account-dropdown">
            <div className="dropdown-container">
                <div className="account-dropdown-details">
                    <div className="account-letter">{name.charAt(0)}</div>
                </div>
                <div className="dropdown-details">
                    <div className="dropdown-name">{name}</div>
                    <div className="dropdown-email">{username}</div>
                </div>
            </div>
            <div className="dropdown-seperator-black" style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }} />
            <div
                className="dropdown-seperator"
                style={{ backgroundColor: isHover ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.1)' }}
            />
            <div
                className="logout-button"
                onClick={handleLogout}
                onMouseOver={() => setIsHover(true)}
                onMouseOut={() => setIsHover(false)}
            >
                <Logout />
                <div className="logout-text">Logout</div>
            </div>
        </div>
    );
};
