import React, { forwardRef } from "react";
import "../Expect.scss";

interface TextBoxProps {
  title: string;
  text: string;
  onClick: () => void;
}

const TextBox = forwardRef<HTMLDivElement, TextBoxProps>(
  ({ title, text, onClick }, ref) => {
    return (
      <div className="expect-text-box" onClick={onClick} ref={ref}>
        <div className="expect-text-title">{title}</div>
        <div className="seperator-container">
          <div className="expect-seperator-black" />
          <div className="expect-seperator" />
        </div>
        <div className="expect-text-desc">{text}</div>
      </div>
    );
  }
);

TextBox.displayName = "TextBox";

export { TextBox };
