import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import './MobLeftMenu.scss';
import Icon from '../../../components/Icon';
import Text from '../../../components/Text';
import LeftMenuControl from '../../../components/LeftMenuControl';
import { Session } from '../../../types';
import { getSessions } from '../../../utilities/apiService';
import { sessionActions, settingsActions } from '../../../actions';
import { history, useAppSelector } from '../../../store';
import { useDispatch } from 'react-redux';
import ReactTimeAgo from 'react-time-ago';
import TimeAgo from 'javascript-time-ago';
import ReactMarkdown from 'react-markdown';
import { getDaycycle } from '../../../common';
import { MainLogo, MobCross, MobDash, MobHelp, MobHelpWhite, MobSess, MobSessWhite } from '../../../assets/img';
import { AccountDropdown, HelpDropdown } from '../../Session/Dropdowns';
import { useMsal } from '@azure/msal-react';
import { FaPlus } from 'react-icons/fa';
import GradientIcon, { GradientIconTypes } from '../../../components/Icon/GradientIcon';
import SessionButton from '../../../components/Icon/SessionButton';

const allowedRoutes = ['/dashboard', '/session'];

const MobLeftMenu = ({ currentPath }) => {
    const [isLeftMenuActive, setIsLeftMenuActive] = useState<boolean>(false);
    const [isSessionActive, setIsSessionActive] = useState<boolean>(false);
    const sessions = useAppSelector(state => state.session.sessions);
    const dispatch = useDispatch();
    const [todaySessions, setTodaySessions] = useState([]);
    const [yesterdaySessions, setYesterdaySessions] = useState([]);
    const [laterSessions, setLaterSessions] = useState([]);
    const { accounts, instance } = useMsal();
    const [isFeedback, setIsFeedback] = useState(false);
    const [isInfoHovered, setIsInfoHovered] = useState(false);
    const [isHelpHovered, setIsHelpHovered] = useState(false);
    const helpRef = useRef(null);
    const infoRef = useRef(null);

    TimeAgo.addLocale({
        locale: 'en',
        now: { now: { current: 'Now', future: 'Now', past: 'Now' } },
        long: {
            year: { past: { one: '{0} year ago', other: '{0} years ago' }, future: 'in {0} years' },
            month: { past: { one: '{0} month ago', other: '{0} months ago' }, future: 'in {0} months' },
            week: { past: { one: '{0} week ago', other: '{0} weeks ago' }, future: 'in {0} weeks' },
            day: { past: { one: '{0} day ago', other: '{0} days ago' }, future: 'in {0} days' },
            hour: { past: { one: '{0} hour ago', other: '{0} hours ago' }, future: 'in {0} hours' },
            minute: { past: { one: '{0} minute ago', other: '{0} minutes ago' }, future: 'in {0} minutes' },
            second: { past: { one: '{0} second ago', other: '{0} seconds ago' }, future: 'in {0} seconds' },
        },
        short: {
            year: { past: '{0} yr ago', future: 'in {0} yr' },
            month: { past: '{0} mo ago', future: 'in {0} mo' },
            week: { past: '{0} wk ago', future: 'in {0} wk' },
            day: { past: '{0} d ago', future: 'in {0} d' },
            hour: { past: '{0} hr ago', future: 'in {0} hr' },
            minute: { past: '{0} min ago', future: 'in {0} min' },
            second: { past: '{0} sec ago', future: 'in {0} sec' },
        },
        narrow: {
            year: { past: '{0}y ago', future: 'in {0}y' },
            month: { past: '{0}m ago', future: 'in {0}m' },
            week: { past: '{0}w ago', future: 'in {0}w' },
            day: { past: '{0}d ago', future: 'in {0}d' },
            hour: { past: '{0}h ago', future: 'in {0}h' },
            minute: { past: '{0}m ago', future: 'in {0}m' },
            second: { past: '{0}s ago', future: 'in {0}s' },
        },
    });

    const handleSessionClick = async (session: Session) => {
        try {
            const sessionsToUpdate = await getSessions();
            sessionActions.loadSessions(sessionsToUpdate)(dispatch);
            if (sessionsToUpdate && sessionsToUpdate.length > 0) {
                const active = sessionsToUpdate.find(s => s.hash_id === session.hash_id);
                if (active) {
                    sessionActions.setActiveSession(active)(dispatch);
                    localStorage.setItem('activeSessionId', active.hash_id);
                    sessionActions.sendMessage(null)(dispatch);
                }
            }
            localStorage.setItem('activeSessionId', session.hash_id);
            history.push(`/session/${session.hash_id}`);
        } catch (error) {
            console.error('Error updating active session:', error);
        }
    };

    const sessionList = (sessionsList, label) => (
        <>
            <div className="menu-seperator" />
            {label}
            {sessionsList.map((session, index) => (
                <>
                    {index === 0 ? <></> : <div className="menu-seperator" />}
                    <div className="session-list-item-wrapper pb-2" key={session.hash_id}>
                        <div className="session-list-item cursor-pointer" onClick={() => handleSessionClick(session)}>
                            <div className="session-content">
                                <Text white left className="mob-session-list-title">
                                    <ReactMarkdown>{session.session_title}</ReactMarkdown>
                                </Text>
                                <div className="d-flex session-card-bottom">
                                    <Text xs white>
                                        <ReactTimeAgo date={new Date(session.updated_at)} locale="en-US" />
                                    </Text>
                                    <Text xs white right className="session-list-item__status">
                                        {session.objective}
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ))}
        </>
    );


    const startNewSession = () => {
        sessionActions.setActiveSession(null)(dispatch);
        localStorage.setItem('activeSessionId', '');
        sessionActions.sendMessage(null)(dispatch);
        setTodaySessions([
            {
                id: -1,
                hash_id: 'new_session',
                goal: null,
                barriers: null,
                enablers: null,
                commitments: null,
                created_at: new Date().toString(),
                updated_at: new Date().toString(),
                messages: [
                    {
                        id: -1,
                        content: `New ${getDaycycle()} session`,
                        reply: '',
                        timestamp: new Date().toString(),
                        isUser: true,
                    },
                ],
                status: 'created',
            },
            ...todaySessions,
        ]);
        history.push(`/session`);
    };

    const handleClose = () => {
        setIsLeftMenuActive(false);
    };

    const handleOpen = () => {
        setIsLeftMenuActive(true);
    };

    const handleLogout = () => {
        instance.logout();
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (helpRef.current && !helpRef.current.contains(event.target as Node)) {
            setIsHelpHovered(false);
        }
        if (infoRef.current && !infoRef.current.contains(event.target as Node)) {
            setIsInfoHovered(false);
        }
    };

    useEffect(() => {
        if (sessions) {
            const today = [];
            const yesterday = [];
            const later = [];

            sessions.forEach(session => {
                const sessionDate = new Date(session.created_at);
                const now = new Date();

                if (sessionDate.toDateString() === now.toDateString()) {
                    today.push(session);
                } else if (sessionDate.toDateString() === new Date(now.setDate(now.getDate() - 1)).toDateString()) {
                    yesterday.push(session);
                } else {
                    later.push(session);
                }
            });

            const sortByDate = (a, b) => {
                const dateA = new Date(a.created_at).getTime();
                const dateB = new Date(b.created_at).getTime();
                return dateB - dateA; // Descending order
            };

            today.sort(sortByDate);
            yesterday.sort(sortByDate);
            later.sort(sortByDate);

            setTodaySessions(today);
            setYesterdaySessions(yesterday);
            setLaterSessions(later);
        }
    }, [sessions]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    return (
        <>
            {allowedRoutes.some(route => currentPath.startsWith(route)) && (
                <>
                    <Icon type="menu_bars" className="menu-bars-dash" onClick={handleOpen} />
                    <div className={`mob-left-menu ${isLeftMenuActive ? '' : 'mob-collpased'}`}>
                        <div className="mob-topbar">
                            <MobCross onClick={handleClose} />
                            <MainLogo className="mob-logo-topbar" />
                            <GradientIcon
                                type={GradientIconTypes.COLORED}
                                icon={<Icon type="add" className="menu-icon add-icon" />}
                                isActive
                                tooltipText="Start new session"
                                tooltipKey="N"
                                onClick={startNewSession}
                            />
                        </div>
                        <div className="mob-bottombar">
                            <div className="mob-menu-items">
                                <MobDash />
                                Dashboard
                            </div>
                            <div className="mob-menu-items session-button">
                                <div className="mob-session-btn" onClick={() => setIsSessionActive(pre => !pre)}>
                                    {isSessionActive ? <MobSessWhite /> : <MobSess />}
                                    Sessions
                                </div>
                                <div className="sessions-list-box">
                                    {isSessionActive &&
                                        !(
                                            todaySessions.length === 0 &&
                                            yesterdaySessions.length === 0 &&
                                            laterSessions.length === 0
                                        ) && (
                                            <>
                                                <div className="session-shadow-top" />
                                                <div className="session-shadow-bottom" />
                                            </>
                                        )}
                                    <div
                                        className={`mob-session-menu ${
                                            isSessionActive
                                                ? todaySessions.length === 0 &&
                                                  yesterdaySessions.length === 0 &&
                                                  laterSessions.length === 0
                                                    ? 'session-open-empty'
                                                    : 'session-open'
                                                : 'session-collapsed'
                                        }`}
                                    >
                                        <div className="session-list-container">
                                            {todaySessions.length > 0 &&
                                                sessionList(
                                                    todaySessions,
                                                    <Text
                                                        left
                                                        white
                                                        className="day-label"
                                                        bold
                                                        xs
                                                        label="eth.left_menu.sessions_list.today"
                                                        message="Today"
                                                    />
                                                )}
                                            {yesterdaySessions.length > 0 &&
                                                sessionList(
                                                    yesterdaySessions,
                                                    <Text
                                                        left
                                                        white
                                                        className="day-label"
                                                        bold
                                                        xs
                                                        label="eth.left_menu.sessions_list.yesterday"
                                                        message="Yesterday"
                                                    />
                                                )}
                                            {laterSessions.length > 0 &&
                                                sessionList(
                                                    laterSessions,
                                                    <Text
                                                        left
                                                        white
                                                        className="day-label"
                                                        bold
                                                        xs
                                                        label="eth.left_menu.sessions_list.later"
                                                        message="Later"
                                                    />
                                                )}
                                            {todaySessions.length === 0 &&
                                                yesterdaySessions.length === 0 &&
                                                laterSessions.length === 0 && (
                                                    <>
                                                        <div className="mob-list-session">No sessions yet.</div>
                                                        <SessionButton
                                                            type={GradientIconTypes.COLORED}
                                                            icon={<Icon type="add" className="menu-icon add-icon" />}
                                                            isActive
                                                            tooltipText="Start new session"
                                                            tooltipKey="N"
                                                            onClick={startNewSession}
                                                        />
                                                    </>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="mob-menu-items position-relative"
                                onClick={() => setIsHelpHovered(true)}
                                ref={helpRef}
                                style={{
                                    backgroundColor: isHelpHovered ? 'rgba(255, 255, 255, 0.1)' : '#222',
                                }}
                            >
                                <div
                                    className="mob-help-hover"
                                    onClick={e => {
                                        e.stopPropagation();
                                        setIsHelpHovered(false);
                                    }}
                                >
                                    {isHelpHovered && <HelpDropdown onFeedback={() => setIsFeedback(true)} />}
                                </div>
                                {isHelpHovered ? <MobHelpWhite /> : <MobHelp />}
                                Help
                            </div>
                            <div
                                className="mob-menu-items position-relative mob-acc-button"
                                onClick={() => setIsInfoHovered(true)}
                                style={{
                                    backgroundColor: isInfoHovered ? 'rgba(255, 255, 255, 0.1)' : '#222',
                                }}
                                ref={infoRef}
                            >
                                <div className="mob-acc-hover">
                                    {isInfoHovered && accounts && accounts.length > 0 && (
                                        <AccountDropdown
                                            name={accounts[0].name}
                                            username={accounts[0].username}
                                            handleLogout={handleLogout}
                                        />
                                    )}
                                </div>
                                <div className="mob-account-details">
                                    {accounts && accounts.length > 0 && (
                                        <div className="mob-account-letter">{accounts[0].name.charAt(0)}</div>
                                    )}
                                </div>
                                {accounts && accounts.length > 0 && accounts[0].name}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default MobLeftMenu;
