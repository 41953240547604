import React from 'react';
import { FormattedMessage } from 'react-intl';
import './Text.scss';

type Props = {
    left?: boolean,
    right?: boolean,
    justify?: boolean,
    center?: boolean,
    fullWidth?: boolean,
    className?: string,
    label?: string,
    message?: string,
    values?: { [key: string]: string | number | null | undefined },
    children?: any,
    error?: boolean,
    success?: boolean,
    xs?: boolean,
    md?: boolean,
    sm?: boolean,
    xl?: boolean,
    xxL?: boolean,
    xxxL?: boolean,
    black?: boolean,
    white?: boolean,
    lightBlack50?: boolean,
    lightBlack80?: boolean,
    bold?: boolean,
    tag?: string,
    h4?: boolean,
    h5?: boolean,
};

const Text = ({
    left,
    right,
    justify,
    center,
    fullWidth,
    className,
    label,
    message,
    values,
    children,
    error,
    success,
    xs,
    sm,
    md,
    xl,
    xxL,
    xxxL,
    black,
    white,
    lightBlack50,
    lightBlack80,
    bold,
    tag,
    h4,
    h5
}: Props) => {

    let Tag = 'span';
     let classes = 'eth-text ';

    if (!tag && (left || right || justify || center || fullWidth)) {
        Tag = 'div';
    } else if (tag) {
        Tag = tag;
        classes += `text-${tag} `;
    }

    if (left) classes += 'text-left ';
    if (right) classes += 'text-right ';
    if (justify) classes += 'text-justify ';
    if (center) classes += 'text-center ';
    if (error) classes += 'text-eth-error ';
    if (success) classes += 'text-eth-green ';
    if (white) classes += 'text-eth-white ';
    if (xs) classes += 'text-xs ';
    if (sm) classes += 'text-sm ';
    if (md) classes += 'text-md ';
    if (xl) classes += 'text-xl ';
    if (xxL) classes += 'text-xxl ';
    if (xxxL) classes += 'text-xxxl ';
    if (black) classes += 'text-eth-black ';
    if (lightBlack50) classes += 'text-eth-black-50 ';
    if (lightBlack80) classes += 'text-eth-black-80 ';
    if (bold) classes += 'fw-bold ';
    if (h4) classes += 'text-h4 ';
    if (h5) classes += 'text-h5 ';

    return (
        // @ts-ignore
        <Tag className={classes + (className || '')}>
            {label &&
                <FormattedMessage id={label} defaultMessage={message} values={(values ? values : {})}/>
            }
            {children &&
                <>{children}</>
            }
        </Tag>
    );
};


export default Text;
