import { settingsConstants } from '../constants';
import { StoreDispatchType } from '../store';

function setIsLeftMenuOpen(isLeftMenuOpen: boolean) {
    return (dispatch: StoreDispatchType) => {
        dispatch((data => {
            return { type: settingsConstants.SET_IS_LEFT_MENU_OPEN, data }
        })({isLeftMenuOpen}));
    };
}

function setIsRightSidebarOpen(isRightSidebarOpen: boolean) {
    return (dispatch: StoreDispatchType) => {
        dispatch((data => {
            return { type: settingsConstants.SET_IS_RIGHT_SIDEBAR_OPEN, data }
        })({isRightSidebarOpen}));
    };
}

export const settingsActions = {
    setIsLeftMenuOpen,
    setIsRightSidebarOpen
};
