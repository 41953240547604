import React, { ForwardedRef, useEffect } from 'react';
import './Ready.scss';
import { FaPlus } from 'react-icons/fa';
import TextInput from '../../../../components/TextInput';
import { ReadyBirdNoise } from '../../../../assets/img';
import { history } from '../../../../store';
import { sessionActions } from '../../../../actions';
import { useDispatch } from 'react-redux';

const Ready = ({ spanRef }: { spanRef: ForwardedRef<HTMLDivElement> }) => {
    const dispatch = useDispatch();
    const submitMessage = message => {
        localStorage.setItem('activeSessionId', '');
        sessionActions.setActiveSession(null)(dispatch);
        sessionActions.sendMessage(message)(dispatch);
        history.push('/session');
    };

    const handleClick = () => {
        window.open(
            'https://docs.google.com/forms/d/e/1FAIpQLSeUICeqeGBTiZTC6o6JMVkwu9ed_8m2k-i0bYadDUqOM0UFDA/viewform',
            '_blank' 
        );
    };

    useEffect(() => {
        sessionActions.setActiveSession(null)(dispatch);
    }, [dispatch]);

    return (
        <>
            <ReadyBirdNoise className="ready-bird" />
            <div className="ready-content">
                <div className="ready-title">
                    <div className="ready-1">Be </div>
                    <div className="ready-2">among </div>
                    <div className="ready-3">the</div>
                    <div className="ready-4">first</div>
                    <div className="ready-5">to</div>
                    <div className="ready-span" ref={spanRef}>
                        register
                    </div>
                </div>
                <TextInput className="ready-input all-border" relativeClassname="ready-input" onSubmit={submitMessage} />
                <div className="ready-desc">
                    Your journey to confident, impactful leadership starts here. With the Leadership Companion, you'll have
                    24/7 access to personalized guidance, ETH-specific insights, and powerful tools to navigate any
                    leadership challenge.
                </div>
                <button className="ready-cta" onClick={handleClick}>
                    <FaPlus size={12} /> <div>Register now</div>
                </button>
            </div>
        </>
    );
};

export default Ready;
