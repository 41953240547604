import React, { useRef, useState } from 'react';
import { CompanionBird, MobileCompanionBird, PlayButton, TabletBirdNoise } from '../../../../assets/img';

import './Companion.scss';
import Loader from '../../../../components/Loader';

interface ICompanionProp {
    companionFirstRef: React.RefObject<HTMLDivElement>;
    companionSecondRef: React.RefObject<HTMLDivElement>;
    companionThirdRef: React.RefObject<HTMLDivElement>;
    companionSpanRef: React.RefObject<HTMLSpanElement>;
    companionTabletRef: React.RefObject<HTMLDivElement>;
}
const Companion = ({
    companionFirstRef,
    companionSecondRef,
    companionThirdRef,
    companionSpanRef,
    companionTabletRef,
}: ICompanionProp) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isPlayButton, setIsPlayButton] = useState(false);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [isVideoLoadedMob, setIsVideoLoadedMob] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const videoMobRef = useRef<HTMLVideoElement>(null);
    const handleVideoLoadedData = () => {
        setIsVideoLoaded(true);
    };
    const handleVideoLoadedMob = () => {
        setIsVideoLoadedMob(true);
    };

    return (
        <div className="companion-container">
            <h2 className="companion-title">
                <div className="companion-subtitle-first" ref={companionFirstRef}>
                    <TabletBirdNoise className="companion-bird-noise" />
                    <span className="companion-1">A</span>
                    <span className="companion-span" ref={companionSpanRef}>
                        <span className="companion-2">confidential</span>
                        <span className="companion-3"> ally,</span>
                    </span>
                    <span className="tablet-bird">
                        <CompanionBird className="companion-bird" />
                    </span>
                    <span className="companion-5">aligned</span>
                    <span className="companion-6"> with</span>
                    <span className="companion-7"> ETH's</span>
                    <span className="companion-8"> values. </span>
                </div>
                <span className="companion-subtitle-second" ref={companionSecondRef}>
                    <span className="companion-9">
                        Imagine being given a companion to support you in all your daily leadership challenges, available
                        24/7 and 100% confidential.
                    </span>
                </span>
            </h2>
            <div className="tablet-container" onMouseOut={() => setIsPlayButton(false)}>
                {isVideoLoaded ? (
                    <div className="play-button">
                        {!isPlaying ? (
                            <img src="/play-button.png" alt="play" />
                        ) : (
                            <img
                                src="/pause-button.png"
                                alt="pause"
                                className="pause-button"
                                style={{ opacity: isPlayButton ? 1 : 0 }}
                            />
                        )}
                    </div>
                ) : (
                    <div className="play-button">
                        <Loader />
                    </div>
                )}
                <div className="video-container" ref={companionTabletRef}>
                    <img src="/tab-side.png" alt="buttons" className="tab-buttons" />
                    <video
                        className="tablet-video"
                        ref={videoRef}
                        onMouseOver={() => setIsPlayButton(true)}
                        onLoadedData={handleVideoLoadedData}
                        onPlay={() => setIsPlaying(true)}
                        onPause={() => setIsPlaying(false)}
                        controls
                    >
                        <source src="/videos/eth-animation_video.mp4" type="video/mp4" />
                    </video>
                </div>
            </div>

            {/* Mobile version */}
            <h2 className="companion-title-mob">
                <TabletBirdNoise className="companion-bird-noise-mob" />
                <div className="companion-subtitle-first-mob">
                    A<span className="companion-span-mob">confidential ally,</span>
                    <span className="">aligned with ETH's values. </span>
                </div>
                <span className="companion-subtitle-second-mob">
                    <MobileCompanionBird className="companion-bird-mob" />
                    Imagine being given a companion to support you in all your daily leadership challenges, available 24/7
                    and 100% confidential.
                </span>
            </h2>
            <div className="tab-container">
                <div className="video-container-mob">
                    <img src="/tab-side.png" alt="buttons" className="tab-buttons-mob" />
                    <video
                        className="tablet-video"
                        ref={videoMobRef}
                        onMouseOver={() => setIsPlayButton(true)}
                        onLoadedData={handleVideoLoadedMob}
                        onPlay={() => setIsPlaying(true)}
                        onPause={() => setIsPlaying(false)}
                        controls
                    >
                        <source src="/videos/eth-animation_video.mp4" type="video/mp4" />
                    </video>
                </div>
                <div
                    className="watch-video"
                    style={{ opacity: isPlaying && isVideoLoadedMob ? 0 : 1 }}
                    onClick={() => {
                        if (videoMobRef && videoMobRef.current) {
                            videoMobRef.current.play();
                        }
                    }}
                >
                    {isVideoLoadedMob ? (
                        <>
                            <PlayButton />
                            Watch video
                        </>
                    ) : (
                        <Loader />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Companion;
