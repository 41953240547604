import React, { useEffect, useState } from 'react';
import './Dashboard.scss';
import Icon from '../../components/Icon';
import Text from '../../components/Text';
import TextInput from '../../components/TextInput';
import { history, useAppSelector } from '../../store';
import { sessionActions, settingsActions } from '../../actions/';
import { useDispatch } from 'react-redux';
import HeaderIllustration from '../../components/HeaderIllustration';
import { getDaycycle } from '../../common';
import { DashboardCard } from './SlidingCards';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { ApproachPoint, ApproachPointUnChecked } from '../../assets/img';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ProfileButtons } from '../../components/ProfileButtons';

const Dashboard = () => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const dispatch = useDispatch();
    const userState = useAppSelector(state => state.auth.user);
    const userName = userState?.name || ""; 
    const nameParts = userName?.split(" ").filter(part => part.trim() !== ""); 
    const firstName = nameParts.length > 1 ? nameParts[1] : nameParts[0]; 

    const dayCycle = getDaycycle();

    const submitMessage = message => {
        sessionActions.sendMessage(message)(dispatch);
        history.push('/session');
    };

    useEffect(() => {
        sessionActions.setActiveSession(null)(dispatch);
        settingsActions.setIsRightSidebarOpen(false)(dispatch);
    }, [dispatch]);

    return (
        <div className="main-dashboard-wrapper">
            <ProfileButtons />
            <div className="dashboard-wrapper pb-5">
                <div className='mob-log-header'>
                    <Logo />
                </div>
                <HeaderIllustration />
                <div className="origami-bird-bg position-absolute z-2" />
                <div className="container z-3 position-relative heading-container">
                    <Icon type="origami" className="position-relative bird-icon" />
                    <Text
                        tag="h1"
                        className="eth-space-top-kilo position-relative header-text-first"
                        label={`eth.dashboard.title1.${dayCycle}`}
                        message={`Good ${dayCycle}, ${firstName}.`}
                    />
                    <Text
                        tag="h1"
                        label="eth.dashboard.title2"
                        className="position-relative header-text-second"
                        message="Let’s make an impact today."
                    />
                    <Text
                        className="eth-space-top-micro dashboard-text"
                        md
                        left
                        label="eth.dashboard.text1"
                        message="To get started, ask a question. Whether you're facing a challenge or seeking to develop your leadership,"
                    />
                    <Text
                        className="eth-space-bottom-kilo dashboard-text"
                        md
                        left
                        label="eth.dashboard.text2"
                        message="I'll guide you in reflecting and uncovering your own insights, offering practical suggestions to support your growth."
                    />
                    <Text
                        className="eth-space-bottom-kilo dashboard-text-mob"
                        md
                        left
                        label="eth.dashboard.text_mob"
                        message="Whether you're facing a challenge or seeking to develop your leadership, I'll guide you in reflecting and uncovering your own insights."
                    />

                    <TextInput onSubmit={submitMessage} className="all-border" />

                    <Text
                        className="eth-space-top-kilo eth-space-bottom-micro dashboard-expect"
                        h4
                        left
                        label="eth.dashboard.benefits.title"
                        message="What you can expect"
                    />
                    <div className="row benefits-row">
                        <div className="col">
                            <div className="d-flex">
                                <div className="benefits-icon-wrapper">
                                    <Icon type="lock" className="benefits-icon"/>
                                </div>
                                <Text h5 label="eth.dashboard.benefits.benefit1.title"
                                      message="Absolute confidentiality"/>
                            </div>
                            <Text left className="eth-space-top-nano" label="eth.dashboard.benefits.benefit1.text"
                                  message="Your conversations are encrypted and never shared. We prioritize your privacy and data security."/>
                        </div>
                        <div className="col">
                            <div className="d-flex">
                                <div className="benefits-icon-wrapper">
                                    <Icon type="timer" className="benefits-icon"/>
                                </div>
                                <Text h5 left label="eth.dashboard.benefits.benefit2.title"
                                      message="24/7 availability"/>
                            </div>
                            <Text left className="eth-space-top-nano" label="eth.dashboard.benefits.benefit2.text"
                                  message="Get instant support whenever you need it, fitting seamlessly into your busy schedule."/>
                        </div>
                        <div className="col">
                            <div className="d-flex">
                                <div className="benefits-icon-wrapper">
                                    <Icon type="visibility" className="benefits-icon"/>
                                </div>
                                <Text h5 left label="eth.dashboard.benefits.benefit2.title"
                                      message="Unbiased perspective"/>
                            </div>
                            <Text left className="eth-space-top-nano" label="eth.dashboard.benefits.benefit2.text"
                                  message="Receive objective advice free from office politics or personal biases, focused solely on your growth."/>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile cards */}
            <div className="dash-card-container">
                <Swiper
                    className="swiper-card-dash"
                    modules={[Pagination]}
                    slidesPerView={'auto'}
                    onActiveIndexChange={swiper => setActiveIndex(swiper.activeIndex)}
                >
                    {DashCards.map((card, index) => (
                        <SwiperSlide key={index}>
                            <DashboardCard icon={card.icon} message={card.message} desc={card.desc} />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="dash-points">
                    {activeIndex === 0 ? <ApproachPoint /> : <ApproachPointUnChecked />}
                    {activeIndex === 1 ? <ApproachPoint /> : <ApproachPointUnChecked />}
                    {activeIndex === 2 ? <ApproachPoint /> : <ApproachPointUnChecked />}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;

const DashCards = [
    {
        icon: 'lock',
        message: 'Absolute confidentiality',
        desc: 'Your conversations are encrypted and never shared. We prioritize your privacy and data security.',
    },
    {
        icon: 'timer',
        message: '24/7 availability',
        desc: 'Get instant support whenever you need it, fitting seamlessly into your busy schedule.',
    },
    {
        icon: 'visibility',
        message: 'Unbiased perspective',
        desc: 'Receive objective advice free from office politics or personal biases, focused solely on your growth.',
    },
];
