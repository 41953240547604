import React from 'react';
import './Button.scss';

type Props = {
    text?: string;
    className?: string;
    btnClass?: string;
    btnIcon?: string;
    type?: 'button' | 'submit' | 'reset';
    onClick?: (e: any) => void;
    disabled?: boolean;
    style?: 'primary' | 'secondary';
    icon?: React.ReactNode;
    inverted?: boolean;
    size?: 'large' | 'small';
};

const Button = ({ text, type, onClick, disabled, style, icon, inverted, size, className, btnClass, btnIcon }: Props) => {
    return (
        <button
            className={`button 
            ${style === 'secondary' ? 'button-secondary' : 'button-primary'} 
            ${inverted ? 'inverted' : ''}
            ${!text && icon ? 'button-only-icon' : ''}
            ${size === 'large' ? '' : 'small'} ${className}`}
            type={type ? type : 'button'}
            {...(onClick ? { onClick: e => onClick(e) } : {})}
            disabled={disabled}
        >
            {icon && <span className={`button-icon ${btnIcon}`}>{icon}</span>}
            {text && <span className={`button-text ${btnClass}`}>{text}</span>}
        </button>
    );
};

export default Button;
