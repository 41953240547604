import React from 'react';
import './Header.scss';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();

    return (
        <div className="header align-items-center position-absolute z-3 header__mob">
            <Logo className="cursor-pointer" onClick={() => navigate('/dashboard')} />
        </div>
    );
};

export default Header;
