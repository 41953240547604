import React, { useRef } from 'react';
import './Innovation.scss';
import { useGSAP } from '@gsap/react';
import { InnovationBird, MobileInnovationBird } from '../../../../assets/img';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

const Innovation = () => {
    const spanRef = useRef(null);
    const desRef = useRef(null);

    useGSAP(() => {
        const tl = gsap.timeline();
        const mm = gsap.matchMedia();

        mm.add('(min-width:450px)', () => {
            gsap.set('.innovation-animation-mob', { display: 'none' });
            gsap.set('.innovation-animation', { display: 'block' });

            tl.fromTo('.innovation', { backgroundSize: '150% 150%' }, { backgroundSize: '100% 100%', duration: 10 }, 0)
                .fromTo(
                    '.innovation-1',
                    { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 5 },
                    0
                )
                .fromTo(
                    '.innovation-2',
                    { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 5.2 },
                    0
                )
                .fromTo(
                    '.innovation-3',
                    { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 5.4 },
                    0
                )
                .fromTo(
                    '.innovation-4',
                    { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 5.6 },
                    0
                )
                .fromTo(
                    '.innovation-5',
                    { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 5.8 },
                    0
                )
                .fromTo(
                    '.innovation-6',
                    { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 10 },
                    0
                )
                .fromTo(
                    '.innovation-7',
                    { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 10.2 },
                    0
                )
                .fromTo(spanRef.current, { backgroundPosition: '0%' }, { backgroundPosition: '-100%', duration: 5 })
                .fromTo(desRef.current, { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 10 }, 0);

            ScrollTrigger.create({
                animation: tl,
                trigger: '.innovation-container',
                toggleActions: 'play reset reverse none',
                start: 'top top',
                end: 'bottom top',
                scrub: true,
                pin: '.innovation-animation',
            });
        });

        mm.add('(max-width:450px)', () => {
            gsap.set('.innovation-animation', { display: 'none' });
            gsap.set('.innovation-animation-mob', { display: 'block' });

            tl.fromTo('.innovation-title-mob', { y: 100, opacity: 0 }, { y: 0, opacity: 1 })
                .fromTo('.innovation-span-mob', { backgroundPosition: '0%' }, { backgroundPosition: '-100%' })
                .fromTo('.innovation-desc-mob', { y: 100, opacity: 0 }, { y: 0, opacity: 1 });
            ScrollTrigger.create({
                animation: tl,
                trigger: '.innovation-container',
                toggleActions: 'play reset reverse none',
                start: 'top top',
                end: 'bottom top',
                scrub: true,
                pin: true,
            });
        });
    }, []);

    return (
        <div className="innovation-container">
            <div className="innovation-animation">
                <div className="innovation-elements">
                    <div className="innovation-title">
                        <span className="innovation-1">By</span>
                        <span className="innovation-span" ref={spanRef}>
                            <span className="innovation-2">ETH</span>
                            <span className="innovation-3">for</span>
                            <span className="innovation-4">ETH.</span>
                        </span>
                    </div>
                    <div className="innovation-bird">
                        <InnovationBird />
                    </div>
                    <div className="innovation-desc" ref={desRef}>
                        The Leadership Companion embodies the values of ETH and its commitment to collaboration and lifelong
                        learning. More than just a tool, it represents a shared culture of responsibility, empowering you to
                        scale your impact, navigate challenges, and achieve your goals with confidence and ease—all while
                        supporting ETH’s vision to pave the way in a complex world.
                    </div>
                </div>
            </div>

            {/* Mobile version */}
            <div className="innovation-animation-mob">
                <div className="innovation-elements-mob">
                    <div className="innovation-title-mob">
                        By
                        <span className="innovation-span-mob">ETH for ETH.</span>
                    </div>
                    <div className="innovation-desc-mob">
                        The Leadership Companion embodies the values of ETH and its commitment to collaboration and lifelong
                        learning. More than just a tool, it represents a shared culture of responsibility, empowering you to
                        scale your impact, navigate challenges, and achieve your goals with confidence and ease—all while
                        supporting ETH’s vision to pave the way in a complex world.
                    </div>
                    <div className="innovation-bird-mob">
                        <MobileInnovationBird />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Innovation;
