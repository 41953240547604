import React from 'react';
import './Footer.scss';
import Text from '../../components/Text';
import { useAppSelector } from '../../store';

const Footer = () => {
    const isLeftMenuActive = useAppSelector(state => state.setting.isLeftMenuOpen);

    return (
        <div className={`footer-container z-1 ${isLeftMenuActive ? 'left-menu-active' : ''}`}>
            <Text
                label="eth.footer.text"
                message="Your data is kept confidential and secure. Review our privacy policy for more information."
                xs
                lightBlack80
            />
        </div>
    );
};

export default Footer;
