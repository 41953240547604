import React from 'react';
import Welcome from './Sections/Welcome';
import Footer from './Sections/Footer';
import FaqsSection from './Sections/Faqs';
import LeaderShipSection from './Sections/Leadership';
import './LandingContainer.scss';
import Innovation from './Sections/Innovation';
import Seconds from './Sections/Seconds';
import { useGSAP } from '@gsap/react';
import gsap, { ScrollTrigger } from 'gsap/all';

function LandingPage() {
    useGSAP(() => {
        const tl = gsap.timeline();
        const mm = gsap.matchMedia();

        mm.add('(min-width:450px)', () => {
            tl.fromTo('.features', { left: 0  }, { left: '-100px', position:'fixed', duration: 10  })
        });

        mm.add('(max-width:450px)', () => {
            gsap.set('.features', {
                display: 'none'
            });
            gsap.set('.features-section', {
                backgroundImage: "url('/mobile-feature.webp')",
                backgroundPosition: 'top',
                backgroundAttachment: 'fixed',
                backgroundSize: 'cover',
            });
        });

        ScrollTrigger.create({
            animation: tl,
            trigger: '.features',
            toggleActions: 'play reset reverse none',
            start: 'top top',
            end: 'bottom top',
            scrub: 1,
        });
    }, []);
    return (
        <>
            <Welcome />
            <section className="features-section">
                <img src='/features.webp' className="features" />
                <LeaderShipSection />
                <FaqsSection />
            </section>
            <section className="innovation">
                <Innovation />
                <Seconds />
            </section>
            <Footer />
        </>
    );
}

export default LandingPage;
