import React, { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import Lottie from 'lottie-react';
import './TextInput.scss';
import Icon from '../Icon';
import GradientIcon, { GradientIconTypes } from '../Icon/GradientIcon';
import Text from '../Text';
import MilestoneSetter from './MilestoneSetter';
import { MilestoneId } from '../../types';
import SpeechMode from './SpeechMode';
import { WebSocketMessage } from '../../utilities/transformMessages';
import { useAppSelector } from '../../store';

const MAX_TOKENS = 3500;
const MAX_CHARS = MAX_TOKENS * 3;
const MAX_HEIGHT = 184;

type Props = {
    onSubmit?: (e: any, show: boolean) => void,
    className?: string
    isProcessing?: boolean
    isSetMilestone?: boolean
    setIsSetMilestone?: (value: boolean) => void
    milestoneType?: MilestoneId
    milestoneText?: string
    isDisabled?: boolean
    relativeClassname?:string;
    updateMilestone?: (milestone: any) => void
    messages?: WebSocketMessage[]
    sendMessage?: (message: string, speech?: boolean) => void
    cancelMilestone?: () => void
    isSpeechMode?: boolean
    setIsSpeechMode?: (value: boolean) => void
};

type OverflowY = 'hidden' | 'scroll' | 'auto' | 'visible';

function TextInput({
    onSubmit,
    className,
    isProcessing,
    isSetMilestone,
    setIsSetMilestone,
    milestoneType,
    milestoneText,
    isDisabled,
    relativeClassname,
    updateMilestone,
    messages,
    sendMessage,
    cancelMilestone,
    isSpeechMode,
    setIsSpeechMode
}: Props) {
    const [inputValue, setInputValue] = useState('');
    const [isTyped, setIsTyped] = useState(false);
    const [overflow, setOverflow] = useState<OverflowY>('hidden');

    const [isWarning, setIsWarning] = useState(false);
    const textareaRef = useRef(null);
    const intl = useIntl();
    const activeSession = useAppSelector(state => state.session.activeSession);

    const adjustHeight = () => {
        const textarea = textareaRef.current;

        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
        if (textarea.scrollHeight > MAX_HEIGHT) {
            setOverflow('auto');
        } else {
            setOverflow('hidden');
        }
    };

    const handleInput = e => {
        adjustHeight();
        setInputValue(e.target.value);

        if (inputValue.length > MAX_CHARS) {
            setIsWarning(true);
        } else {
            setIsWarning(false);
        }
    };

    const handleKeyDown = event => {
        if (isWarning || isProcessing) return;
        if (event.key === 'Enter' && !event.shiftKey) {
            if (inputValue.trim() !== '') {
                event.preventDefault();
                onSubmit(inputValue, false);
                setInputValue('');
                textareaRef.current.style.height = '64px';
                textareaRef.current.focus();
            }
        }
    };

    const onButtonClick = () => {
        if (isWarning || isProcessing) return;
        if (isTyped) {
            if (inputValue.trim() !== '') {
                onSubmit(inputValue, false);
                setInputValue('');
                textareaRef.current.focus();
            }
        } else if (!isProcessing && !isTyped) {
            setIsSpeechMode(!isSpeechMode);
        }
    };

    const getIcon = () => {
        if (isProcessing) return <Icon type="cross" className="menu-icon" />;
        else if (isTyped) return <Icon type="arrow_forward" className="menu-icon" />;
        else return <Icon type="microphone" className="menu-icon" />;
    };

    useEffect(() => {
        if (inputValue.trim() !== '') {
            setIsTyped(true);
        } else {
            setIsTyped(false);
        }
        if (!isProcessing && textareaRef.current) {
            textareaRef.current.focus();
        }
    }, [inputValue, isProcessing]);

    useEffect(() => {
        if (!activeSession) {
            setInputValue('');
        }
    }, [activeSession]);

    return (
        <div
            className={`position-relative text-input-outer-wrapper ${relativeClassname} ${
                isSetMilestone ? 'milestone' : ''
            }`}
        >
            {isSetMilestone ? (
                <MilestoneSetter
                    milestoneType={milestoneType}
                    milestoneText={milestoneText}
                    setIsSetMilestone={setIsSetMilestone}
                    updateMilestone={updateMilestone}
                    cancelMilestone={cancelMilestone}
                    textareaRef={textareaRef}
                />
            ) : isSpeechMode ? (
                <SpeechMode
                    isSpeechMode={isSpeechMode}
                    setIsSpeechMode={setIsSpeechMode}
                    messages={messages}
                    sendMessage={sendMessage}
                    isProcessing={isProcessing}
                />
            ) : (
                <div className={`text-input-wrapper ${className ? className : ''} ${isProcessing ? 'processing' : ''}`}>
                    <div className="text-input-shadow"></div>
                    <GradientIcon
                        type={GradientIconTypes.COLORED}
                        icon={getIcon()}
                        isActive
                        className="text-input__microphone position-absolute"
                        shadow={true}
                        onClick={onButtonClick}
                        tooltipText={
                            isWarning &&
                            intl.formatMessage({
                                id: 'eth.text_input.tooltip',
                                defaultMessage: 'Message is too long',
                            })
                        }
                        isTooltipTop={isWarning}
                    />
                    <textarea
                        className="text-input w-100"
                        placeholder={
                            isProcessing
                                ? ''
                                : intl.formatMessage({
                                      id: 'eth.text_input.placeholder',
                                      defaultMessage: "What's on your mind?",
                                  })
                        }
                        value={inputValue}
                        onChange={e => handleInput(e)}
                        onKeyDown={handleKeyDown}
                        disabled={isProcessing || isDisabled}
                        rows={1}
                        ref={textareaRef}
                        style={{
                            overflowY: overflow,
                        }}
                        autoFocus
                    />
                    {isProcessing && (
                        <div className="text-input-processing-placeholder">
                            <Lottie
                                className="placeholder-icon"
                                animationData={require('../../assets/lottie/eth-bird.json')}
                                loop={true}
                            />
                            <Text className="placeholder-text" label="eth.text_input.processing" message="Processing" />
                            <div className="wave"></div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default TextInput;
