import React, { useEffect, useState } from 'react';
import './LeftMenu.scss';
import Icon from '../../components/Icon';
import Text from '../../components/Text';
import LeftMenuControl from '../../components/LeftMenuControl';
import { Session } from '../../types';
import { getSessions } from '../../utilities/apiService';
import { sessionActions } from '../../actions';
import { history, useAppSelector } from '../../store';
import { useDispatch } from 'react-redux';
import ReactTimeAgo from 'react-time-ago';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { getDaycycle } from '../../common';
import ReactMarkdown from 'react-markdown';


const LeftMenu = ({currentPath}) => {
    const isLeftMenuActive = useAppSelector(state => state.setting.isLeftMenuOpen);
    const sessions = useAppSelector(state => state.session.sessions);
    const dispatch = useDispatch();
    const [todaySessions, setTodaySessions] = useState([]);
    const [yesterdaySessions, setYesterdaySessions] = useState([]);
    const [laterSessions, setLaterSessions] = useState([]);

    TimeAgo.addLocale({
        locale: 'en',
        now: {now: {current: 'Now', future: 'Now', past: 'Now',}},
        long: {
            year: {past: {one: '{0} year ago', other: '{0} years ago'}, future: 'in {0} years',},
            month: {past: {one: '{0} month ago', other: '{0} months ago'}, future: 'in {0} months',},
            week: {past: {one: '{0} week ago', other: '{0} weeks ago'}, future: 'in {0} weeks',},
            day: {past: {one: '{0} day ago', other: '{0} days ago'}, future: 'in {0} days',},
            hour: {past: {one: '{0} hour ago', other: '{0} hours ago'}, future: 'in {0} hours',},
            minute: {past: {one: '{0} minute ago', other: '{0} minutes ago'}, future: 'in {0} minutes',},
            second: {past: {one: '{0} second ago', other: '{0} seconds ago'}, future: 'in {0} seconds',},
        },
        short: {
            year: {past: '{0} yr ago', future: 'in {0} yr',},
            month: {past: '{0} mo ago', future: 'in {0} mo',},
            week: {past: '{0} wk ago', future: 'in {0} wk',},
            day: {past: '{0} d ago', future: 'in {0} d',},
            hour: {past: '{0} hr ago', future: 'in {0} hr',},
            minute: {past: '{0} min ago', future: 'in {0} min',},
            second: {past: '{0} sec ago', future: 'in {0} sec',},
        },
        narrow: {
            year: {past: '{0}y ago', future: 'in {0}y',},
            month: {past: '{0}m ago', future: 'in {0}m',},
            week: {past: '{0}w ago', future: 'in {0}w',},
            day: {past: '{0}d ago', future: 'in {0}d',},
            hour: {past: '{0}h ago', future: 'in {0}h',},
            minute: {past: '{0}m ago', future: 'in {0}m',},
            second: {past: '{0}s ago', future: 'in {0}s',},
        },
    });

    const handleSessionClick = async (session: Session) => {
        try {
            const sessionsToUpdate = await getSessions();
            sessionActions.loadSessions(sessionsToUpdate)(dispatch);
            if (sessionsToUpdate && sessionsToUpdate.length > 0) {
                const active = sessionsToUpdate.find(s => s.hash_id === session.hash_id);
                if (active) {
                    sessionActions.setActiveSession(active)(dispatch);
                    localStorage.setItem('activeSessionId', active.hash_id);
                    sessionActions.sendMessage(null)(dispatch);
                }
            }
            localStorage.setItem('activeSessionId', session.hash_id);
            history.push(`/session/${session.hash_id}`);
        } catch (error) {
            console.error('Error updating active session:', error);
        }
    };

    const sessionList = (sessionsList, label) => (
        <>
            {label}
            {sessionsList.map((session) => (
                <div className="session-list-item-wrapper pb-2" key={session.hash_id}>
                    <div className="session-list-item cursor-pointer"
                         onClick={() => handleSessionClick(session)}>
                        <div className="session-content">
                            <Text white left className="session-list-item__title">
                                <ReactMarkdown>{session.session_title ? session.session_title : session.messages[0]?.content}</ReactMarkdown>
                            </Text>
                            <div className="d-flex">
                                <Text xs white><ReactTimeAgo date={new Date(session.updated_at)} locale="en-US"/></Text>
                                <Text xs white right className="session-list-item__status">{session.objective}</Text>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );

    const startNewSession = () => {
        sessionActions.setActiveSession(null)(dispatch);
        localStorage.setItem('activeSessionId', '');
        sessionActions.sendMessage(null)(dispatch);

        if (!todaySessions.find((s) => (s.id === -1))) {
            setTodaySessions([{
                id: -1,
                hash_id: 'new_session',
                goal: null,
                barriers: null,
                enablers: null,
                commitments: null,
                created_at: (new Date()).toString(),
                updated_at: (new Date()).toString(),
                messages: [{
                    id: -1,
                    content: `New ${getDaycycle()} session`,
                    reply: '',
                    timestamp: new Date().toString(),
                    isUser: true,
                }],
                status: 'created',
            }, ...todaySessions]);
        }

        history.push(`/session`);
    };

    useEffect(() => {
        if (sessions) {
            const today = [];
            const yesterday = [];
            const later = [];

            sessions.forEach(session => {
                const sessionDate = new Date(session.created_at);
                const now = new Date();

                if (sessionDate.toDateString() === now.toDateString()) {
                    today.push(session);
                } else if (sessionDate.toDateString() === new Date(now.setDate(now.getDate() - 1)).toDateString()) {
                    yesterday.push(session);
                } else {
                    later.push(session);
                }
            });

            const sortByDate = (a, b) => {
                const dateA = new Date(a.created_at).getTime();
                const dateB = new Date(b.created_at).getTime();
                return dateB - dateA; // Descending order
            };

            today.sort(sortByDate);
            yesterday.sort(sortByDate);
            later.sort(sortByDate);

            setTodaySessions(today);
            setYesterdaySessions(yesterday);
            setLaterSessions(later);
        }
    }, [sessions]);


    return (
        <div className={`left-menu ${isLeftMenuActive ? '' : 'collapsed'}`}>
            <div className="segment-container">
                <div className="square">
                    <div className="segment"></div>
                </div>
            </div>
            <div className="segment-container bottom">
                <div className="square">
                    <div className="segment"></div>
                </div>
            </div>
            {currentPath !== '/login' &&
                <LeftMenuControl startNewSession={startNewSession}/>
            }
            <div className="menu-content">
                <div className="menu-title">
                    <Text className="title-text text-l text-eth-white" bold label="eth.menu.title" message="Sessions"/>
                    <div className="menu-title-icons">
                        <Icon type="reader_mode" className="icon"/>
                        <Icon type="trailing" className="icon"/>
                    </div>
                </div>
                <div className="session-list-container">
                    {todaySessions.length > 0 &&
                        sessionList(todaySessions, <Text left white className="session-list-container__day-label" bold
                                                         xs label="eth.left_menu.sessions_list.today" message="Today"/>)
                    }
                    {yesterdaySessions.length > 0 &&
                        sessionList(yesterdaySessions, <Text left white className="session-list-container__day-label"
                                                             bold xs label="eth.left_menu.sessions_list.yesterday"
                                                             message="Yesterday"/>)
                    }
                    {laterSessions.length > 0 &&
                        sessionList(laterSessions, <Text left white className="session-list-container__day-label" bold
                                                         xs label="eth.left_menu.sessions_list.later" message="Later"/>)
                    }
                    {todaySessions.length === 0 && yesterdaySessions.length === 0 && laterSessions.length === 0 &&
                        <div className="session-list-empty">
                            <Text white bold xs label="eth.left_menu.sessions_list.empty"
                                  message="No sessions available"/>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default LeftMenu;
