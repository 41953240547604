import React from 'react';
import { ExpectNoise, MobileExpectBird } from '../../../../assets/img';
import { TextBox } from './TextBox';
import { textContent } from '../../../../constants';

import './Expect.scss';
import Lottie from 'lottie-react';

const Expect = ({
    handleCard,
    textBoxRefs,
    textBoxMobRefs,
}: {
    textBoxRefs: React.RefObject<HTMLDivElement[]>;
    textBoxMobRefs: React.RefObject<HTMLDivElement[]>;
    handleCard: (index: number) => void;
}) => {
    return (
        <div className="expect-container">
            <div className="expect-left-container">
                <Lottie
                    className="loader-icon expect-bird"
                    animationData={require('../../../../assets/lottie/eth-bird.json')}
                    loop={true}
                />
                <ExpectNoise className="expect-noise" />
            </div>
            <div className="expect-right-container">
                <h2 className="expect-heading">
                    <span className="expect-1">What</span>
                    <span className="expect-2">is</span>
                    <span className="expect-highlight">
                        <span className="expect-3">awaiting</span>
                        <span className="expect-4">you.</span>
                    </span>
                </h2>
                {textContent.map((item, index) => (
                    <TextBox
                        key={index}
                        title={item.title}
                        text={item.text}
                        ref={el => (textBoxRefs.current[index] = el)}
                        onClick={() => {
                            handleCard(index);
                        }}
                    />
                ))}
            </div>

            {/* Mobile version */}
            <div className="expect-container-mob">
                <h2 className="expect-heading-mob">
                    What is <span className="expect-highlight-mob">awaiting you.</span>
                </h2>
                <div className="expect-left-container-mob">
                    <MobileExpectBird className="expect-bird-mob" />
                    <ExpectNoise className="expect-noise-mob" />
                </div>
                <div className="expect-right-container-mob">
                    {textContent.map((item, index) => (
                        <TextBox
                            key={index}
                            title={item.title}
                            text={item.text}
                            ref={el => (textBoxMobRefs.current[index] = el)}
                            onClick={() => {
                                handleCard(index);
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Expect;
