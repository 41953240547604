import React from "react";
import "./Loader.scss";
import Lottie from "lottie-react";
import Text from "../Text";

const Loader = () => {
    return (
        <div className="loader-wrapper">
            <Lottie className="loader-icon" animationData={require('../../assets/lottie/eth-bird.json')} loop={true} />
            <Text className="loader-text" label="eth.text_input.processing" message="Loading..."/>
            <div className="wave"></div>
        </div>
    );
};

export default Loader;
