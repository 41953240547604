import React from 'react';
import './Footer.scss';
import Icon from '../../../../components/Icon';
import { MainLogo } from '../../../../assets/img';

export default function Footer() {
    return (
        <footer className="footer">
            <div className="footerContainer">
                <div className="footerMain">
                    <div className="footerSection">
                        <MainLogo className="footer-logo" />
                        <p className="feedbackQuestion">Got any questions or feedback?</p>
                        <a href="/contact" className="footerEmail">
                            Send email
                        </a>
                    </div>

                    <div className="footerMidSection">
                        <h2 className="footerSectionTitle">Explore further</h2>
                        <ul className="footerLinks">
                            <li>
                                <a href="https://ethz.ch/content/dam/ethz/main/news/globe/Web/2023/Globe2301_Emotionen.pdf" target="_blank" rel="noopener noreferrer">ETH Globe 2023 - Pages 24-28</a>
                                ...
                            </li>
                            <li>
                                <a href="https://academic.oup.com/edited-volume/34535/chapter-abstract/292966331?redirectedFrom=fulltext" target="_blank" rel="noopener noreferrer">Leadership, Psychological Well-Being, and O</a>
                                ...
                            </li>
                            <li>
                                <a href="https://www.emerald.com/insight/content/doi/10.1108/jmd-06-2024-0182/full/html" target="_blank" rel="noopener noreferrer">Unearthing AI coaching chatbots capabilities</a>
                                ...
                            </li>
                            <li>
                                <a href="https://www.nature.com/articles/523279a" target="_blank" rel="noopener noreferrer">Lifelong learning: Science professors need l</a>
                                ...
                            </li>
                            <li>
                                <a href="https://www.research-collection.ethz.ch/bitstream/handle/20.500.11850/348306/2368_Kowatschetal2017-THCBPEACHWorkshop.pdf?sequence=1&isAllowed=y" target="_blank" rel="noopener noreferrer">Text-based Healthcare Chatbots Supporting</a>
                                ...
                            </li>
                            <li>
                                <a href="https://ethz.ch/en/news-and-events/eth-news/news/2024/10/you-can-ask-a-chatbot-things-you-might-not-dare-to-ask-in-a-lecture.html" target="_blank" rel="noopener noreferrer">You can ask a chatbot things you might not d</a>
                                ...
                            </li>
                            <li>
                                <a href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0270255" target="_blank" rel="noopener noreferrer">Comparing artificial intelligence and human</a>
                                ...
                            </li>
                            <li>
                                <a href="https://www.forschung-und-lehre.de/heftarchiv/ausgabe-6/24" target="_blank" rel="noopener noreferrer">Forschung & Lehre</a>
                            </li>
                        </ul>
                    </div>

                    <div className="footerEndSection ">
                        <h2 className="footerSectionTitle">Follow us</h2>
                        <div className="footerSocial">
                            <a href="https://x.com/ETH" target="_blank" rel="noreferrer" aria-label="Follow us on X (Twitter)">
                                <Icon type={'twitter'} />
                            </a>
                            <a href="https://www.linkedin.com/school/eth-zurich" target="_blank" rel="noreferrer" aria-label="Follow us on LinkedIn">
                                <Icon type={'linkedIn'} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer-seperator-black" />
                <div className="footer-seperator" />
                <div className="footerBottom">
                    <div className='copyrights'>© 2024 Swiss Federal University of Technology Zurich</div>
                    <div className="footerLegal">
                        <a href="/site-notice">Site notice</a>
                        <a href="/accessibility">Accessibility statement</a>
                        <a href="/disclaimer">Disclaimer & copyright</a>
                        <a href="/privacy">Data privacy</a>
                    </div>
                    <div className="footerLegal-mob-top">
                        <a href="/site-notice">Site notice</a>
                        <a href="/accessibility">Accessibility statement</a>
                    </div>
                    <div className="footerLegal-mob-bottom">
                        <a href="/disclaimer">Disclaimer & copyright</a>
                        <a href="/privacy">Data privacy</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
