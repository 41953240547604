import React, { useCallback, useEffect, useRef, useState } from 'react';
import './RightSidebar.scss';
import Text from '../../components/Text';
import Icon from '../../components/Icon';
import { Document, Page, pdfjs } from 'react-pdf';
import { getDocument } from '../../utilities/apiService';
import { Session } from '../../types';
import MilestoneCard from '../../components/MilestoneCard';
import { getMilestoneByBackendVariable } from '../../utilities/milestonesUtils';
import { useAppSelector } from '../../store';
import { MilestoneSessionModal } from './MilestoneSessionModal';
import { sessionActions, settingsActions } from '../../actions';
import { useDispatch } from 'react-redux';
import { ResourcesModal } from './ResourceModal';

interface RightSidebarProps {
    session: Session;
    sources: string[];
    links: string[];
    currentPath: string;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


const RightSidebar = ({session, sources, links, currentPath}: RightSidebarProps) => {
    const [documentToShow, setDocumentToShow] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [scrolledToBottom, setScrolledToBottom] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const re = /(?:\.([^.]+))?$/;
    const [pdfString, setPdfString] = useState('');
    const [numberOfPages, setNumberOfPages] = useState<number>();
    const dispatch = useDispatch();
    const basePath = '/pdfs';
    const [height, setHeight] = useState(0);
    
    const isRightSidebarOpen = useAppSelector(state => state.setting.isRightSidebarOpen);
    const isSessionOverviewModal = useAppSelector(state => state.session.sessionOverview);
    const handleOverviewModal = (state: boolean) => {
        sessionActions.setActiveSessionOverview(state)(dispatch);
    };
   
    const isSessionResourcesModal = useAppSelector(state => state.session.sessionResource);

    const handleResourcesModal = (state: boolean) => {
        sessionActions.setActiveSessionResource(state)(dispatch);
    };

    const checkRightSidebar = useCallback((activeSession, sourcesData, links) => {
        if (activeSession && currentPath === `/session/${activeSession.hash_id}`) {
            if ((activeSession.problem && activeSession.problem.length > 0)
                || (activeSession.goal && activeSession.goal.length > 0)
                || (activeSession.solution && activeSession.solution.length > 0)
                || (sourcesData && sourcesData.length > 0)
                || (links && links.length > 0)
            ) {
                setIsOpen(true);
                settingsActions.setIsRightSidebarOpen(true)(dispatch);
            } else {
                setIsOpen(false);
                settingsActions.setIsRightSidebarOpen(false)(dispatch);
            }
        } else {
            setIsOpen(false);
            settingsActions.setIsRightSidebarOpen(false)(dispatch);
        }
    }, [currentPath]);

    useEffect(() => {
        checkRightSidebar(session, sources, links)
    }, [session, sources, links, currentPath, checkRightSidebar]);

    const handleScroll = () => {
        if (wrapperRef.current) {
            setScrolledToBottom(Math.abs((wrapperRef.current.scrollHeight - (wrapperRef.current.scrollTop + wrapperRef.current.clientHeight))) <= 1);
            setHeight(wrapperRef.current.scrollHeight);
        }
    };

    const handleShowingDocument = (fileName: string) => {
        setDocumentToShow(fileName);
        getDocument(fileName).then((res) => {
            setPdfString(res.file_content);
        });
    };

    const downloadFileFromContent = (fileName) => {
        getDocument(fileName).then((res) => {
            const binaryString = atob(res.file_content);

            const binaryData = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                binaryData[i] = binaryString.charCodeAt(i);
            }

            const blob = new Blob([binaryData], {type: 'application/octet-stream'});
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        });
    };

    return (
        <>
            {isSessionOverviewModal && <MilestoneSessionModal Session={session} setIsModal={handleOverviewModal} />}

            {isSessionResourcesModal && 
            <ResourcesModal 
                basePath={basePath} 
                height={height} 
                wrapperRef={wrapperRef} 
                handleScroll={handleScroll} 
                scrolledToBottom={scrolledToBottom}
                links={links} 
                sources={sources} 
                handleShowingDocument={handleShowingDocument} 
                downloadFileFromContent={downloadFileFromContent} 
                handleResourcesModal={handleResourcesModal}
            />}
            <div className={`right-sidebar z-3 ${isOpen ? 'open' : ''}`}>
                <div className="right-sidebar__sessions-box">
                    {['problem', 'goal', 'solution', 'action']
                        .map((type) => ({
                            type,
                            content: session?.[type],
                        }))
                        .filter(({ content }) => content?.length > 0)
                        .map(({ type, content }) => (
                            <MilestoneCard
                                key={type}
                                milestone={getMilestoneByBackendVariable(type)}
                                milestoneContent={[content]}
                            />
                        ))
                    }
                </div>
                <div className="right-sidebar__sources">
                    <div className="right-sidebar__sources--title py-3">
                        <Icon type="link" className="me-2"/>
                        <Text h5 bold label="eth.right_sidebar.sources.title" message="Links & sources"/>
                    </div>
                    <div className="right-sidebar__sources--wrapper pt-3" ref={wrapperRef} onScroll={handleScroll}>
                        {links.length > 0 &&
                            <div className="links-wrapper">
                                {links.map((link: any, idx: number) => (
                                    <div key={idx} className="link">
                                        <div dangerouslySetInnerHTML={{__html: link[0]}} />
                                        <Icon type="launch" className="ms-2 mt-1"/>
                                    </div>
                                ))}
                            </div>
                        }
                        {sources.length > 0 &&
                            <>
                                {sources.map((source: any, idx) => (
                                    <div key={idx} className="file d-flex flex-row mb-2 cursor-pointer"
                                         onClick={() => {
                                             if (re.exec(source.name)[1] === 'pdf') {
                                                 handleShowingDocument(source.name);
                                             } else {
                                                 downloadFileFromContent(source.name);
                                             }
                                         }}>
                                        <div className="file__extension">
                                            <Text bold h5 white>{re.exec(source.name)[1]}</Text>
                                        </div>
                                        <div className="file__wrapper">
                                            <div className="file__name">
                                                <Text left bold h5>{source.name}</Text>
                                            </div>
                                            <div className="file__date">
                                                <Text className="file__last-updated"
                                                      label="eth.right_sidebar.sources.date"
                                                      message="Last updated"/>
                                                &nbsp;<Text
                                                className="file__last-updated">{source.updated_at}</Text>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        }
                    </div>
                    {!scrolledToBottom &&
                        <div className="sources-gradient position-absolute bottom-0 w-100"></div>
                    }
                </div>
            </div>
            {documentToShow &&
                <div className="document-wrapper position-absolute">
                    <div className="document-title cursor-pointer" onClick={() => setDocumentToShow('')}>
                        <Icon type="arrow_back" className="me-2"/>
                        <Text h5 bold>{documentToShow.split(re.exec(documentToShow)[0])[0]}</Text>
                    </div>
                    <Document file={`data:application/octet-stream;base64,${pdfString}`}
                              onLoadSuccess={(pdf) => setNumberOfPages(pdf.numPages)}>
                        {[...Array(numberOfPages)].map((_, i) => <Page className="mb-3" key={i} pageNumber={i + 1}/>)}
                    </Document>
                </div>
            }
        </>
    );
};

export default RightSidebar;
