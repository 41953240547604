import React, { useState } from 'react';
import './MilestoneCard.scss';
import { Milestone } from '../../types';
import Text from '../Text';

interface MilestoneCardProps {
    milestone: Milestone;
    milestoneContent: string[];
}

const MilestoneCard = ({milestone, milestoneContent}: MilestoneCardProps ) => {
    const [isCardOpen, setIsCardOpen] = useState(true);

    return (
        <div className="milestone-card-outer-wrapper" key={milestone?.id}>
            <div className="milestone-card-shadow"></div>
            <div className={`milestone-card-inner-wrapper ${isCardOpen ? 'open-card' : 'close-card'}`}>
                <div className="milestone-card-title-wrapper"  onClick={() => setIsCardOpen(!isCardOpen)}>
                    <div className="milestone-card-title-left-wrapper">
                        {milestone.iconCard}
                        <div className="milestone-card-title">{milestone.label}</div>
                    </div>
                    <div className="milestone-card-title-right">
                        {isCardOpen ? 'Hide' : 'Show'}
                    </div>
                </div>
                <div className="milestone-card-separator-wrapper">

                </div>
                <div className="milestone-card-content-wrapper">
                    <div className="milestone-card-content">
                        {milestoneContent && milestoneContent.length > 0 && milestoneContent.map((content, index) => (
                            <div className="milestone-card-content-item">
                                {/* TODO: add support for multiple milestone values*/}
                                {/* <div className="milestone-card-content-index-wrapper" key={index}>
                                    <div className="milestone-card-content-index">{index + 1}</div>
                                </div> */}
                                <div className="milestone-card-content-text">
                                    <Text white className="w-100">
                                        <div dangerouslySetInnerHTML={{__html: content}} />
                                    </Text>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MilestoneCard;
