import React, { useEffect, useRef, useState } from 'react';
import './SessionHeader.scss';
import Text from '../../../components/Text';
import Icon from '../../../components/Icon';
import { deleteSession } from '../../../utilities/apiService';
import HeaderIllustration from '../../../components/HeaderIllustration';
import { getDaycycle } from '../../../common';
import { history, useAppSelector } from '../../../store';
import { sessionActions } from '../../../actions';
import { useDispatch } from 'react-redux';
import ReactMarkdown from 'react-markdown';

type Props = {
    title: string;
    sessionHash: string;
};

const SessionHeader = ({ title, sessionHash }: Props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [displayedTitle, setDisplayedTitle] = useState(null);
    const ref = useRef<HTMLDivElement | null>(null);
    const refDesktop = useRef<HTMLDivElement | null>(null);
    const refIcon = useRef<HTMLDivElement | null>(null);
    const helpDesktopRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useDispatch();
    const sessions = useAppSelector(state => state.session.sessions);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const isLeftMenuActive = useAppSelector(state => state.setting.isLeftMenuOpen);
    const isRightSidebarOpen = useAppSelector(state => state.setting.isRightSidebarOpen);

    const handleMenuClick = (state: boolean) => {
        setIsMenuOpen(state);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            ref.current &&
            !ref.current.contains(event.target as Node) &&
            refIcon.current &&
            !refIcon.current.contains(event.target as Node) &&
            refDesktop.current &&
            !refDesktop.current.contains(event.target as Node) &&
            helpDesktopRef.current &&
            !helpDesktopRef.current.contains(event.target as Node)
        ) {
            setIsMenuOpen(false);
        }
    };

    const handleDeleteSession = (hash: string) => {
        try {
            deleteSession(hash)
                .then(() => {
                    localStorage.setItem('activeSessionId', '');
                    sessionActions.setActiveSession(null)(dispatch);
                    sessionActions.loadSessions(sessions.filter(s => s.hash_id !== hash))(dispatch);
                    history.push('/session');
                    setIsMenuOpen(false);
                })
                .catch(() => {
                    console.log('Error occurred when deleting sessions');
                });
        } catch (error) {
            console.error('Error occurred when deleting sessions', error);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleOverviewModal = (state: boolean) => {
        sessionActions.setActiveSessionOverview(state)(dispatch);
        setIsMenuOpen(false);
    };

    const handleResourcesModal = (state: boolean) => {
        sessionActions.setActiveSessionResource(state)(dispatch);
        setIsMenuOpen(false);
    };

    useEffect(() => {
        if (!title) {
            setDisplayedTitle(null);
            return;
        }
    
        let index = 0;
        let currentTitle = "";
    
        intervalRef.current = setInterval(() => {
            if (index < title.length) {
                currentTitle += title[index]; 
                setDisplayedTitle(currentTitle); 
                index++;
            } else {
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }
            }
        }, 70); 
    
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [title]); 

    return (
        <>
            <div className="session-header__mob-header">
                <div className="session-header__mob-title">
                    {displayedTitle ? (
                        <Text left>
                            <ReactMarkdown>{displayedTitle}</ReactMarkdown>
                        </Text>
                    ) : (
                        <Text
                            left
                            label="eth.sessions_header.empty_session.title"
                            message="New {dayCycle} session"
                            values={{ dayCycle: getDaycycle() }}
                        />
                    )}
                </div>
                <div
                    className="session-header__mob-icon-container"
                    ref={refIcon}
                    onClick={() => handleMenuClick(!isMenuOpen)}
                >
                    {isMenuOpen ? (
                        <Icon type="more" className="session-header__mob-icon cursor-pointer" />
                    ) : (
                        <Icon type="more_dark" className="session-header__mob-icon-dark cursor-pointer" />
                    )}
                </div>
                {isMenuOpen && (
                    <div ref={ref} className="session-header__menu-mob position-absolute">
                        <div
                            className="d-flex cursor-pointer p-3 align-items-center gap-2"
                            onClick={() => {
                                handleResourcesModal(false);
                                handleOverviewModal(true);
                            }}
                        >
                            <Icon type="session_bars" />
                            <Text white bold label="eth.session_header.menu.report_problem" message="Session overview" />
                        </div>
                        <div className="headers-seperator-black" />
                        <div className="headers-seperator-custom" />
                        <div
                            className="d-flex cursor-pointer p-3 align-items-center gap-2"
                            onClick={() => {
                                handleOverviewModal(false);
                                handleResourcesModal(true);
                            }}
                        >
                            <Icon type="link_chain" />
                            <Text white bold label="eth.session_header.menu.report_problem" message="Links & resources" />
                        </div>
                        <div className="headers-seperator-black" />
                        <div className="headers-seperator-custom" />
                        <div className="d-flex cursor-pointer p-3 align-items-center gap-2">
                            <Icon type="flag" />
                            <Text white bold label="eth.session_header.menu.report_problem" message="Report a problem" />
                        </div>
                        <div className="headers-seperator-black" />
                        <div className="headers-seperator-custom" />
                        <div
                            className="d-flex cursor-pointer p-3 align-items-center gap-2"
                            onClick={() => handleDeleteSession(sessionHash)}
                        >
                            <Icon type="delete" />
                            <Text bold error label="eth.session_header.menu.delete_session" message="Delete session" />
                        </div>
                    </div>
                )}
            </div>
            <div className="session-header position-absolute w-100 z-2">
                <HeaderIllustration small={true} />

                <div className={`session-header__container position-relative container d-flex ${isLeftMenuActive ? 'left-menu-active': ''} ${isRightSidebarOpen ? 'right-menu-active': ''}`}>
                    {displayedTitle && (
                        <Text left className="session-header__title">
                            <ReactMarkdown>{displayedTitle}</ReactMarkdown>
                        </Text>
                    )}
                    {!displayedTitle && (
                        <Text
                            left
                            className="session-header__title"
                            label="eth.sessions_header.empty_session.title"
                            message="New {dayCycle} session"
                            values={{ dayCycle: getDaycycle() }}
                        />
                    )}
                    <div
                        className="session-hover-button"
                        onMouseEnter={() => handleMenuClick(true)}
                        onMouseLeave={() => handleMenuClick(false)}
                    >
                        <div ref={helpDesktopRef}>
                            {isMenuOpen ? (
                                <Icon type="more" className="session-header__icon cursor-pointer" />
                            ) : (
                                <Icon type="more_dark" className="icon-dark cursor-pointer" />
                            )}
                        </div>
                        {isMenuOpen && (
                            <div ref={refDesktop} className="session-header__menu position-absolute">
                                <div
                                    className="d-flex cursor-pointer p-3 align-items-center gap-2"
                                    onClick={() => {
                                        handleResourcesModal(false);
                                        handleOverviewModal(true);
                                    }}
                                >
                                    <Icon type="session_bars" />
                                    <Text
                                        white
                                        bold
                                        label="eth.session_header.menu.report_problem"
                                        message="Session overview"
                                    />
                                </div>
                                <div className="headers-seperator-black" />
                                <div className="headers-seperator-custom" />
                                <div
                                    className="d-flex cursor-pointer p-3 align-items-center gap-2"
                                    onClick={() => {
                                        handleOverviewModal(false);
                                        handleResourcesModal(true);
                                    }}
                                >
                                    <Icon type="link_chain" />
                                    <Text
                                        white
                                        bold
                                        label="eth.session_header.menu.report_problem"
                                        message="Links & resources"
                                    />
                                </div>
                                <div className="headers-seperator-black" />
                                <div className="headers-seperator-custom" />
                                <div className="d-flex cursor-pointer p-3 align-items-center gap-2">
                                    <Icon type="flag" />
                                    <Text
                                        white
                                        bold
                                        label="eth.session_header.menu.report_problem"
                                        message="Report a problem"
                                    />
                                </div>
                                <div className="headers-seperator-black" />
                                <div className="headers-seperator-custom" />
                                <div
                                    className="d-flex cursor-pointer p-3 align-items-center gap-2"
                                    onClick={() => handleDeleteSession(sessionHash)}
                                >
                                    <Icon type="delete" />
                                    <Text
                                        bold
                                        error
                                        label="eth.session_header.menu.delete_session"
                                        message="Delete session"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SessionHeader;
