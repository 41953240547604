import React from 'react';
import './ResourcesModal.scss';
import Icon from '../../../components/Icon';
import Text from '../../../components/Text';

interface IResourcesModalProp {
    links: string[];
    sources: string[];
    wrapperRef: React.MutableRefObject<HTMLDivElement>;
    scrolledToBottom: boolean;
    height: number;
    basePath: string;
    handleScroll: () => void;
    handleShowingDocument: (value: React.SetStateAction<string>) => void;
    downloadFileFromContent: (fileName: string) => void;
    handleResourcesModal: (state: boolean) => void;
}

export const ResourcesModal = ({
    sources,
    links,
    scrolledToBottom,
    wrapperRef,
    height,
    basePath,
    handleScroll,
    handleShowingDocument,
    downloadFileFromContent,
    handleResourcesModal,
}: IResourcesModalProp) => {
    const re = /(?:\.([^.]+))?$/;
    return (
        <div className="resource-overview">
            <div className="resource-overview-header">
                <div className="resource-overview-title">
                    <Icon type="link_chain" className="milestone-mob-icon" />
                    Links & sources
                </div>
                <div onClick={() => handleResourcesModal(false)}>
                    <Icon type="cross" className="milestone-mob-icon" />
                </div>
            </div>

            <div className="resource-seperator-black" />
            <div className="resource-seperator-custom" />

            {(links.length > 0 || sources.length > 0) && (
                <div
                    className="resources-modal__sources"
                    ref={wrapperRef}
                    onScroll={handleScroll}
                    style={{ height: height ? `${height}px` : '30%' }}
                >
                    <div className="resources-modal__sources--wrapper">
                        {links.length > 0 && (
                            <div className="links-wrapper">
                                {links.map((link: any) => (
                                    <div key={link.id} className="link">
                                        <a href={`${basePath}/${link.name}`} target="_blank">
                                            <Text xl className='link-style'>{link.name}</Text>
                                        </a>
                                        <Icon type="launch_white" className="ms-2 mt-1 launch-color" />
                                    </div>
                                ))}
                            </div>
                        )}
                        {sources.length > 0 && (
                            <>
                                {sources.map((source: any, idx) => (
                                    <div
                                        key={idx}
                                        className="file d-flex flex-row mb-2 cursor-pointer"
                                        onClick={() => {
                                            if (re.exec(source.name)[1] === 'pdf') {
                                                handleShowingDocument(source.name);
                                            } else {
                                                downloadFileFromContent(source.name);
                                            }
                                        }}
                                    >
                                        <div className="file__extension">
                                            <Text bold h5 white>
                                                {re.exec(source.name)[1]}
                                            </Text>
                                        </div>
                                        <div className="file__wrapper">
                                            <div className="file__name">
                                                <Text left bold h5>
                                                    {source.name}
                                                </Text>
                                            </div>
                                            <div className="file__date">
                                                <Text
                                                    className="file__last-updated"
                                                    label="eth.right_sidebar.sources.date"
                                                    message="Last updated"
                                                />
                                                &nbsp;<Text className="file__last-updated">{source.updated_at}</Text>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
