import React, { useRef } from 'react';
import { Seconds1, Seconds2, Seconds3, Seconds4, SecondsLine } from '../../../../assets/img';

import './Seconds.scss';
import { useGSAP } from '@gsap/react';
import gsap, { ScrollTrigger } from 'gsap/all';
import Ready from '../Ready';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

const cardsData = [
    {
        image: <img src="/seconds1.png" alt="1" className="seconds-card-screen" />,
        icon: <Seconds1 />,
        title: 'Start',
        desc: 'Log in using your existing ETH account or create a new one.',
    },
    {
        image: <img src="/seconds2.png" alt="2" className="seconds-card-screen" />,
        icon: <Seconds2 />,
        title: 'Engage',
        desc: 'Interact with your AI Companion through chat or voice.',
    },
    {
        image: <img src="/seconds3.png" alt="3" className="seconds-card-screen" />,
        icon: <Seconds3 />,
        title: 'Reflect & grow',
        desc: 'Receive practical support and develop your own solutions.',
    },
    {
        image: <img src="/seconds4.png" alt="4" className="seconds-card-screen" />,
        icon: <Seconds4 />,
        title: 'Transform your leadership',
        desc: 'Make sustainable progress and celebrate success.',
    },
];

const Seconds = () => {
    const spanRef = useRef(null);
    const spanReadyRef = useRef(null);
    const spanMobReadyRef = useRef(null);

    useGSAP(() => {
        const tl = gsap.timeline();
        const mm = gsap.matchMedia();
        mm.add('(min-width:450px)', () => {
            gsap.set('.seconds-background', { display: 'block' });
            gsap.set('.seconds-line', { display: 'block' });
            gsap.set('.afternoon-color', { display: 'block' });
            gsap.set('.seconds-content', { display: 'block' });
            gsap.set('.ready-container', { display: 'block' });

            gsap.set('.seconds-background-mob', { display: 'none' });
            gsap.set('.seconds-line-mob', { display: 'none' });
            gsap.set('.afternoon-color-mob', { display: 'none' });
            gsap.set('.seconds-content-mob', { display: 'none' });
            gsap.set('.ready-container-mob', { display: 'none' });

            tl.fromTo('.seconds-background', { opacity: 0 }, { opacity: 1, duration: 6 })
                .fromTo(
                    '.seconds-1',
                    { y: 200, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 6 },
                    1
                )
                .fromTo(
                    '.seconds-2',
                    { y: 200, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 7 },
                    1
                )
                .fromTo(
                    '.seconds-3',
                    { y: 200, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 8 },
                    1
                )
                .fromTo(
                    '.seconds-span',
                    { y: 200, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 9 },
                    1
                )
                .fromTo(
                    '.seconds-line',
                    { y: 200, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 10 },
                    1
                )
                .fromTo(spanRef.current, { backgroundPosition: '0%' }, { backgroundPosition: '-100%', duration: 11 })
                .fromTo('.cards-container', { x: '100%', y: '100%', opacity: 0 }, { x: '-50%', y: '-28%', opacity: 1 }, 0)
                .fromTo('.seconds-card-0', { x: '100%', opacity: 0 }, { x: '0%', opacity: 1, duration: 10 })
                .fromTo('.seconds-card-1', { x: '100%', opacity: 0 }, { x: '0%', opacity: 1, duration: 10 })
                .fromTo('.seconds-card-2', { x: '100%', opacity: 0 }, { x: '0%', opacity: 1, duration: 10 })
                .fromTo('.seconds-card-3', { x: '100%', opacity: 0 }, { x: '0%', opacity: 1, duration: 10 })
                .to({}, { duration: 2 })
                .to('.seconds-title', { y: '-100%', opacity: 0, margin: 0 })
                .to('.cards-container', { y: '-100%', opacity: 0, margin: 0 })
                .to('.seconds-line', { opacity: 0 })

                .fromTo('.ready-container', { y: '100%' }, { y: '0%', duration: 2 })
                .fromTo('.afternoon-color', { y: '-100%' }, { y: '0', duration: 2 })
                .fromTo(
                    '.ready-1',
                    { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 2 },
                    80
                )
                .fromTo(
                    '.ready-2',
                    { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 3 },
                    80
                )
                .fromTo(
                    '.ready-3',
                    { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 4 },
                    80
                )
                .fromTo(
                    '.ready-4',
                    { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 5 },
                    80
                )
                .fromTo(
                    '.ready-5',
                    { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 6 },
                    80
                )
                .fromTo(
                    spanReadyRef.current,
                    { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 7 },
                    80
                )
                .fromTo(spanReadyRef.current, { backgroundPosition: '0%' }, { backgroundPosition: '-100%' })
                .fromTo('.ready-input', { y: '100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 2 })
                .fromTo('.ready-desc', { y: '100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 2 })
                .fromTo('.ready-cta', { y: '100%', opacity: 0 }, { y: '0%', opacity: 1, duration: 2 })
                .to({}, { duration: 5 });

            ScrollTrigger.create({
                animation: tl,
                trigger: '.seconds-container',
                toggleActions: 'play reset reverse none',
                start: 'top top',
                end: '+=500%',
                scrub: true,
                pin: true,
            });
        });

        mm.add('(max-width:450px)', () => {
            gsap.set('.seconds-background', { display: 'none' });
            gsap.set('.seconds-line', { display: 'none' });
            gsap.set('.afternoon-color', { display: 'none' });
            gsap.set('.seconds-content', { display: 'none' });
            gsap.set('.ready-container', { display: 'none' });
            gsap.set('.cards-container', { display: 'none' });
            gsap.set('.seconds-title', { display: 'none' });

            gsap.set('.seconds-background-mob', { display: 'block' });
            gsap.set('.seconds-line-mob', { display: 'block' });
            gsap.set('.afternoon-color-mob', { display: 'block' });
            gsap.set('.seconds-content-mob', { display: 'block' });
            gsap.set('.ready-container-mob', { display: 'block' });

            tl.fromTo('.seconds-background-mob', { opacity: 0 }, { opacity: 1 }, 0)
                .fromTo('.seconds-span-mob', { backgroundPosition: '0%' }, { backgroundPosition: '-100%' })
                .fromTo('.seconds-title-mob', { opacity: 0 }, { opacity: 1 }, 0)
                .fromTo('.seconds-line-mob', { opacity: 0 }, { opacity: 1 }, 0)
                .fromTo('.cards-container-mob', { x: '100%', opacity: 0 }, { x: '0%', opacity: 1 }, 0)
                .fromTo('.seconds-card-1-mob', { x: '100%', opacity: 0 }, { x: '0%', opacity: 1 }, 0)
                .fromTo('.seconds-card-2-mob', { x: '100%', opacity: 0 }, { x: '0%', opacity: 1 }, 0)
                .fromTo('.seconds-card-3-mob', { x: '100%', opacity: 0 }, { x: '0%', opacity: 1 }, 0)
                .fromTo('.seconds-card-4-mob', { x: '100%', opacity: 0 }, { x: '0%', opacity: 1 }, 0)
                .fromTo('.seconds-content-mob', { y: '0', opacity: 1 }, { y: '-100%', opacity: 0, margin: 0 })
                .to('.seconds-line-mob', { opacity: 0 })
                .fromTo('.ready-container-mob', { y: '100%' }, { y: '-68%' })
                .fromTo('.afternoon-color-mob', { y: '-100%' }, { y: '0' })
                .fromTo('.ready-title-mob', { y: '100%', opacity: 0 }, { y: '0%', opacity: 1 })
                .fromTo(spanMobReadyRef.current, { backgroundPosition: '0%' }, { backgroundPosition: '-100%' })
                .fromTo('.ready-input-mob', { y: '100%', opacity: 0 }, { y: '0%', opacity: 1 })
                .fromTo('.ready-desc-mob', { y: '100%', opacity: 0 }, { y: '0%', opacity: 1 })
                .fromTo('.ready-cta-mob', { y: '100%', opacity: 0 }, { y: '0%', opacity: 1 });
            ScrollTrigger.create({
                animation: tl,
                trigger: '.seconds-container',
                toggleActions: 'play reset reverse none',
                start: 'top top',
                end: '+=800%',
                scrub: true,
                pin: true,
            });
        });
    }, []);

    return (
        <div className="seconds-container">
            <img src="/background-seconds.png" alt="background" className="seconds-background" />
            <div className="seconds-line" />

            <div className="afternoon-color" />
            <div className="cards-container">
                {cardsData.map((card, index) => (
                    <div className={`seconds-card-${index} card-container`} key={index}>
                        <div className="seconds-card-background" />
                        {card.image}
                        <div className="seconds-card-details">
                            {card.icon}
                            <div className="seconds-desc-container">
                                <div className="seconds-card-title">{card.title}</div>
                                <div className="seconds-card-desc">{card.desc}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="seconds-title">
                <span className="seconds-1">Get</span>
                <span className="seconds-2">started</span>
                <span className="seconds-3">in</span>
                <span className="seconds-span" ref={spanRef}>
                    seconds.
                </span>
            </div>
            <div className="ready-container">
                <Ready spanRef={spanReadyRef} />
            </div>

            {/* Mobile Version */}
            <div className="seconds-background-mob" />
            <SecondsLine className="seconds-line-mob" />
            <div className="afternoon-color-mob" />
            <div className="seconds-content-mob">
                <div className="seconds-title-mob">
                    Get started in <span className="seconds-span-mob">seconds.</span>
                </div>
                <div className="cards-container-mob">
                    <Swiper spaceBetween={10} width={320} slidesPerView={1} pagination={false} modules={[Pagination]}>
                        {cardsData.map((card, index) => (
                            <SwiperSlide key={index}>
                                <div className={`seconds-card-${index + 1}-mob`} key={index}>
                                    <div className="seconds-card-background-mob" />
                                    <div className={`seconds-card-screen-mob seconds-card-bg-${index + 1}`}></div>
                                    <div className="seconds-card-details-mob">
                                        {card.icon}
                                        <div className="seconds-desc-container-mob">
                                            <div className="seconds-card-title-mob">{card.title}</div>
                                            <div className="seconds-card-desc-mob">{card.desc}</div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
            <div className="ready-container-mob">
                <Ready spanRef={spanMobReadyRef} />
            </div>
        </div>
    );
};

export default Seconds;
