import React, { useCallback, useEffect, useRef } from 'react';
import { NavigationIndicator } from '@reverse-ui/react';
import { createRoot } from 'react-dom/client';
import "./ProgressBar.scss";
import { MILESTONES } from '../../constants/session.constants';
import Tooltip from '../Tooltip';
import Icon from '../Icon';

const BAR_CONTAINER_SELECTOR = '.MuiBox-root.css-j7qwjs';
const BAR_LINE_SELECTOR = '.MuiBox-root.css-1rmpr0s';
const LINES_COUNT = 85;
const MILESTONE_POINT_INDEX = 21;

const ProgressBar = ({ activeSession, onMilestoneClick }) => {
    const containerRef = useRef(null);
    const tooltipRoots = useRef(new Map());

    const attachMilestoneHandlers = useCallback((items) => {
        items.forEach((item, index) => {
            const milestoneIndex = Math.floor(index / MILESTONE_POINT_INDEX);
            const milestone = MILESTONES[milestoneIndex];
            const isActive = index === 0
                || (milestoneIndex < MILESTONES.length && milestoneIndex !== index / MILESTONE_POINT_INDEX)
                ? Boolean(activeSession && activeSession[MILESTONES[milestoneIndex + 1]?.id])
                : Boolean(activeSession && activeSession[milestone?.id]);

            item.classList.toggle('active', isActive);

            if (index === 0 && !item.classList.contains('active')) {
                item.classList.add('active');
            }

            if (milestone && (index === 0 || index % MILESTONE_POINT_INDEX === 0)) {
                const existingTooltip = item.querySelector('.progress-bar-milestone-tooltip');

                if (existingTooltip) {
                    const root = tooltipRoots.current.get(existingTooltip);
                    if (root) {
                        root.render(
                            <Tooltip
                                key={milestone.id}
                                text={milestone.timelineState}
                                iconLeft={
                                    isActive || index === 0 ? (
                                        milestone.iconTimeline
                                    ) : (
                                        <Icon type="lock" className="milestone-icon" />
                                    )
                                }
                                inactive={!(isActive || index === 0)}
                            />
                        );
                    }
                } else {
                    item.classList.add('progress-bar-milestone-point-wrapper');
                    item.onclick = () => onMilestoneClick?.(milestone.id);
    
                    const existingChild = item.firstChild;
        
                    if (existingChild) {
                        existingChild.classList.add('progress-bar-milestone-point');
                        const additionalContent = document.createElement('div');
                        additionalContent.className = 'progress-bar-milestone-tooltip';
                        additionalContent.setAttribute('data-milestone-id', milestone.id);

                        const root = createRoot(additionalContent);
                        tooltipRoots.current.set(additionalContent, root);

                        root.render(
                            <Tooltip
                                key={milestone.id}
                                text={milestone.timelineState}
                                iconLeft={
                                    isActive ? (
                                        milestone.iconTimeline
                                    ) : (
                                        <Icon type="lock" className="milestone-icon" />
                                    )
                                }
                                inactive={!isActive}
                            />
                        );

                        item.appendChild(additionalContent);
                    }
                }
            }
        });
    }, [activeSession]);

    useEffect(() => {
        const container = containerRef.current?.querySelector(BAR_CONTAINER_SELECTOR);

        if (!container) return;

        const waitForRender = () => {
            const items = container.querySelectorAll(BAR_LINE_SELECTOR);
            if (items.length === LINES_COUNT) {
                attachMilestoneHandlers(items);
                observer.disconnect();
            }
        };

        const observer = new MutationObserver(waitForRender);
        observer.observe(container, { childList: true, subtree: true });

        waitForRender();

        return () => {
            observer.disconnect();
    
            const items = container?.querySelectorAll(BAR_LINE_SELECTOR);
            items?.forEach((item) => {
                item.classList.remove('progress-bar-milestone-point-wrapper');
                const tooltip = item.querySelector('.progress-bar-milestone-tooltip');
                if (tooltip) {
                    const root = tooltipRoots.current.get(tooltip);
                    if (root) {
                        root.unmount();
                        tooltipRoots.current.delete(tooltip);
                    }
                    tooltip.remove();
                }
                if (item.classList.contains('progress-bar-milestone-point')) {
                    item.onclick = null;
                }
            });
        };
    }, [activeSession, attachMilestoneHandlers, onMilestoneClick]);

    return (
        <div ref={containerRef} className="progress-bar-wrapper z-2">
            <NavigationIndicator items={Array(LINES_COUNT).fill('')} />
        </div>
    );
};

export default ProgressBar;
