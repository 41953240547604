import React from "react";
import "./GradientIcon.scss";
import Tooltip from "../../Tooltip";

export enum GradientIconTypes {
    COLORED = "colored",
    GRAY = "gray"
}

export interface GradientIconProps {
    type: GradientIconTypes;
    icon?: React.ReactNode;
    isActive?: boolean;
    shadow?: boolean;
    tooltipText?: string;
    tooltipKey?: string;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    children?: React.ReactNode;
    isTooltipTop?: boolean;
}

const GradientIcon = ({
    type,
    onClick,
    icon,
    isActive,
    tooltipText,
    tooltipKey,
    children,
    className,
    shadow,
    isTooltipTop
}: GradientIconProps) => {
    return (
        <div
            className=
                {`gradient-circle-wrapper cursor-pointer ${type} ${isActive && !isTooltipTop ? "" : "inactive"} ${className ? className : "" } ${shadow ? "gradient-shadow" : "" }`}
            onClick={(onClick ? onClick : () => {})}
        >
            <div className="gradient-circle">
                <div className="background-circle">
                </div>
            </div>
            <div className="child-wrapper">
                {children ? children : icon}
            </div>
            {tooltipText &&
                <Tooltip text={tooltipText} tooltipKey={tooltipKey} top={isTooltipTop} />
            }
        </div>
    );
};

export default GradientIcon;
