import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { CrossIcon, EscKey, ThankYouBird, WhiteCrossIcon } from '../../../../assets/img';
import './ThankYou.scss';

export const ThankYouModal = ({ onClose }: { onClose: Dispatch<SetStateAction<boolean>> }) => {
    useEffect(() => {
        const handleEscKey = event => {
            if (event.key === 'Escape') {
                onClose(false);
            }
        };

        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [onClose]);
    return (
        <div className="thankyou-container">
            <div className="thankyou-modal">
                <div className="thankyou-header">
                    <div className="thankyou-text-container">
                        <div>
                            <ThankYouBird />
                        </div>
                        <div className="thankyou-text-heading">Thank you!</div>
                    </div>
                    <div className="thankyou-close-container">
                        <div>
                            <EscKey />
                        </div>
                        <div className="thankyou-close" onClick={() => onClose(false)}>
                            <CrossIcon />
                        </div>
                    </div>
                </div>
                <div className="thankyou-seperator" />
                <div className="thankyou-body">
                    Your feedback is crucial in helping us enhance our Leadership Coaching Companion to better meet your
                    needs.
                </div>
                <div className="thankyou-footer" onClick={() => onClose(false)}>
                    <div className="white-cross-icon">
                        <WhiteCrossIcon className="margin-top" />
                    </div>
                    <div>Close</div>
                </div>
            </div>
        </div>
    );
};
