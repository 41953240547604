import React, { useState } from 'react';
import { HelpIcon, HelpIconDark } from '../../assets/img';
import { AccountDropdown, HelpDropdown } from '../../containers/Session/Dropdowns';
import FeedbackModal from '../../containers/Session/SessionModal';
import { useMsal } from '@azure/msal-react';
import { ThankYouModal } from '../../containers/Session/SessionModal/ThankYouModal';

import './ProfileButtons.scss';

export const ProfileButtons = () => {
    const [isFeedback, setIsFeedback] = useState(false);
    const [isThankYou, setIsThankYou] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isHelpHovered, setIsHelpHovered] = useState(false);
    const { accounts, instance } = useMsal();

    const handleLogout = () => {
        instance.logout();
    };
    return (
        <>
            <FeedbackModal isOpen={isFeedback} onClose={() => setIsFeedback(false)} setIsThankYou={setIsThankYou} />
            {isThankYou && <ThankYouModal onClose={setIsThankYou} />}
            <div
                className="help-hover"
                onMouseOut={() => setIsHelpHovered(false)}
                onMouseOver={() => setIsHelpHovered(true)}
            >
                {isHelpHovered && <HelpDropdown onFeedback={() => setIsFeedback(true)} />}

                <div className="help-icons">{isHelpHovered ? <HelpIcon /> : <HelpIconDark />}</div>
            </div>

            <div className="account-hover" onMouseOut={() => setIsHovered(false)} onMouseOver={() => setIsHovered(true)}>
                {isHovered && accounts && accounts.length > 0 && (
                    <AccountDropdown name={accounts[0].name} username={accounts[0].username} handleLogout={handleLogout} />
                )}
                <div className="account-details">
                    {accounts && accounts.length > 0 && <div className="account-letter">{accounts[0].name.charAt(0)}</div>}
                </div>
            </div>
        </>
    );
};
