import { useCallback, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../store";
import { transformMessages, WebSocketMessage } from "../utilities/transformMessages";

const useWebSocket = (
    url: string,
    token: string,
    onlySend = false,
    _session?: any,
) => {
    const session = useAppSelector(state => state.session);
    const [messages, setMessages] = useState<WebSocketMessage[]>([]);
    const [objective, setObjective] = useState("None");
    const [problem, setProblem] = useState("None");
    const [goal, setGoal] = useState("None");
    const [solution, setSolution] = useState("None");
    const [action, setAction] = useState("None");
    const [sessionTitle, setSessionTitle] = useState(null);
    const [sources, setSources] = useState([]);
    const [links, setLinks] = useState([]);
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [messagesProcessed, setMessagesProcessed] = useState(true);
    const [sessionSummary, setSessionSummary] = useState({
        summaryText: null,
        summaryTitle: "",
    });
    const [milestone, setMilestone] = useState(null);
    const chatIdRef = useRef(""); // Use useRef for chatId
    const regCountRef = useRef(0); // Use useRef for regCount
    const wsRef = useRef<WebSocket | null>(null);
    const resetChat = () => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.close();
        }
        setMessages([]);
        setObjective("None");
        setProblem("None");
        setGoal("None");
        setSessionSummary({
            summaryText: null,
            summaryTitle: "",
        });
        setSolution("None");
        setAction("None");
        setSources([]);
        setLinks([]);
        chatIdRef.current = ""; 
        regCountRef.current = 0; 
    };

    useEffect(() => {
        if (session.activeSession?.hash_id && messagesProcessed) {
            setMessages(transformMessages(session.activeSession?.messages));
            if (session.activeSession?.session_summary) {
                setSessionSummary({
                    summaryText: session.activeSession?.session_summary,
                    summaryTitle: session.activeSession?.problem,
                });
            }
        }
    }, [session.activeSession?.hash_id, session.activeSession?.messages]);

    const connectWebSocket = useCallback(() => {
        console.log("Connecting to WebSocket...");
        if (!url || !token) return;
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            console.log("WebSocket is already open");
            return;
        }
        wsRef.current = new WebSocket(url);

        wsRef.current.onopen = () => {
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.send(
                    JSON.stringify({
                        type: "auth",
                        token,
                        chatid: session?.activeSession?.hash_id || "",
                    })
                );
            }
            console.log("WebSocket connection established.");
            setObjective("Discovery");
        };

        wsRef.current.onerror = (error) => {
            setAuthenticated(false);
            console.error("WebSocket error:", error);
            setTimeout(connectWebSocket, 2000);
        };

        wsRef.current.onclose = (e) => {
            console.log("WebSocket connection closed. Reconnecting...");
            console.error("WS close event:", e);
            setTimeout(connectWebSocket, 2000);
        };

        wsRef.current.onmessage = (event) => {
            handleMessage(JSON.parse(event.data));
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, token]);

    useEffect(() => {
        connectWebSocket();

        return () => {
            wsRef.current?.close();
        };
    }, [url, token, session.message, connectWebSocket]);

    const handleMessage = (data: any) => {
        if (data.chatid && data.chatid.trim() !== "") {
            //console.log("Updating chatId to:", data.chatid);
            chatIdRef.current = data.chatid; // Update chatId using useRef
        } else {
            console.warn("Received empty or missing chatId in data:", data);
            setIsProcessing(false);
        }
        console.log("Received data:", data);
        switch (data.type) {
            case "update":
                // Check if data.state exists and is not null/undefined
                if (data.state && typeof data.state === 'object') {
                    const new_state = data.state;
                    if (new_state.objective) setObjective(new_state.objective);
                    if (new_state.problem) setProblem(new_state.problem);
                    if (new_state.goal) setGoal(new_state.goal);
                    if (new_state.solution) setSolution(new_state.solution);
                    if (new_state.action) setAction(new_state.action);
                } else {
                    if (data.objective) setObjective(data.objective);
                    if (data.problem) setProblem(data.problem);
                    if (data.goal) setGoal(data.goal);
                    if (data.solution) setSolution(data.solution);
                    if (data.action) setAction(data.action);
                }
                console.log("Updated state:", data.state);
                break;
            case "sources":
                setSources(data.sources);
                setLinks(data.links);
                break;

            case "milestone":
                let milestone_type = data.milestone_type;
                let milestone_value = data.milestone_value;
                setMilestone({
                    milestoneType: milestone_type,
                    milestoneText: milestone_value,
                    isSetting: true,
                    error: null,
                });
                break;
            case "milestone_updated":
                setMilestone({
                    milestoneType: data.milestone_type,
                    milestoneText: data.milestone_value,
                    isSetting: false,
                    error: null,
                });
                break;
            
            case "session_title":
                setSessionTitle(data.session_title); 
                break;
            case "error": 
                setMilestone({
                    milestoneType: data.milestone_type,
                    milestoneText: data.milestone_value,
                    isSetting: false,
                    error: data.error
                })
                break;
                
            case "start":
                setIsProcessing(true);
                setMessagesProcessed(false);
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { content: "", isUser: false, finished: false },
                ]);
                break;
            case "content":
                setMessages((prevMessages) => [
                    ...prevMessages.slice(0, -1),
                    {
                        ...prevMessages[prevMessages.length - 1],
                        content:
                            prevMessages[prevMessages.length - 1]?.content + data.content,
                    },
                ]);
                break;
            case "end":
                setIsProcessing(false);
                setMessages((prevMessages) => [
                    ...prevMessages.slice(0, -1),
                    {
                        ...prevMessages[prevMessages.length - 1],
                        finished: true,
                        speechMode: data.speechMode,
                    },
                ]);
                break;

            case "session_summary":
                setSessionSummary({
                    summaryText: data.summary_text,
                    summaryTitle: sessionTitle,
                });
                break;
            case "messages_processed":
                setMessagesProcessed(true);
                break;
            case "auth_success":
                console.log("Auth success");
                setAuthenticated(true);
                break;
            case "auth_reject":
                console.log("Auth reject");
                break;
            case "switch_session":
                console.log("Session switched to", data.chatid);
                break;
            default:
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { content: data.content, type: data.type, isUser: false },
                ]);
                break;
        }
    };

    const switchSession = (newChatId: string) => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            console.log("Switching session to", newChatId);
            chatIdRef.current = newChatId; // Update chatIdRef
            wsRef.current.send(
                JSON.stringify({
                    type: "switch_session",
                    chatid: newChatId,
                    token,
                })
            );
        } else {
            console.warn("WebSocket is not open. Unable to switch sessions.");
        }
    };

    const updateMilestone = (milestone) => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(
                JSON.stringify({
                    type: "update_milestone",
                    milestone_type: milestone.milestoneType,
                    milestone_value: milestone.milestoneText,
                    chatid: session.activeSession?.hash_id || chatIdRef.current || "",
                    token,
                })
            );
        }
    };

    const cancelMilestone = () => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(
                JSON.stringify({
                    type: "cancel_milestone_setting",
                    chatid: session.activeSession?.hash_id || chatIdRef.current || "",
                    token,
                })
            );
        }
    };

    const requestSessionSummary = () => {
        return new Promise<boolean>((resolve) => {
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                const handleMessage = (event: MessageEvent) => {
                    const data = JSON.parse(event.data);
                    if (data.type === 'session_summary') {
                        wsRef.current?.removeEventListener('message', handleMessage);
                        resolve(true);
                    }
                };

                wsRef.current.addEventListener('message', handleMessage);

                wsRef.current.send(JSON.stringify({
                    type: "session_summary",
                    chatid: session.activeSession?.hash_id || chatIdRef.current || "",
                    token,
                }));
            } else {
                resolve(false);
            }
        });
    };

    const deleteTurn = (index: number) => {
      
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(JSON.stringify({
                type: "delete_turn",
                index,
                chatid: session.activeSession?.hash_id || chatIdRef.current || "",
                token,
            }));
        }
        
        // Remove the message at the index and the one before it
        // resulting in the turn being deleted, where index is the companion message index
        // if index is -1, remove the last two messages
    
        setMessages((prevMessages) => {
            if (index === -1 && prevMessages.length >= 2) {
                return prevMessages.slice(0, -2);
            } else if (index > 0 && index < prevMessages.length) {
                return [
                    ...prevMessages.slice(0, index - 1),
                    ...prevMessages.slice(index + 1),
                ];
            }
            return prevMessages;
        });
    };

    const sendMessage = (
        message: string,
        show: boolean,
        initial: boolean = false
    ) => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            regCountRef.current += 1; // Increment regCountRef

            console.log('chatId', chatIdRef.current);
            console.log('session.activeSession.hash_id', session.activeSession?.hash_id);

            const data = JSON.stringify({
                speechMode: show,
                chatid: initial ? "" : session.activeSession?.hash_id || chatIdRef.current || "", // Use chatId from useRef
                type: "content",
                repid: `user${regCountRef.current}`, // Use regCountRef for repid
                content: escapeHTML(message).replace(/\n/g, "<br>"),
                objective,
                sources,
                token,
            });

            wsRef.current.send(data);

            setMessages((prevMessages) => [
                ...prevMessages,
                { content:  escapeHTML(message).replace(/\n/g, "<br>"), type: "content", isUser: true, speechMode: show },
            ]);
        }
    };

    if (onlySend) {
        return {
            sendMessage
        };
    } else {
        return {
            messages,
            sendMessage,
            deleteTurn,
            objective,
            problem,
            sources,
            goal,
            solution,
            action,
            links,
            resetChat,
            connectWebSocket,
            switchSession,
            requestSessionSummary,
            sessionSummary,
            sessionTitle,
            isAuthenticated,
            isProcessing,
            milestone,
            updateMilestone,
            cancelMilestone,
            chatId: chatIdRef.current, // Expose chatId for external use
        };
    }
}

function escapeHTML(str: string) {
    return str
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

export default useWebSocket;
