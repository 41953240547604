import React from 'react';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import './styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
// import { intlReducer } from 'react-intl-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { MsalProvider } from '@azure/msal-react';

import "./styles/custom_bootstrap.scss";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { history, store } from "./store";
import { AuthenticationResult, EventType } from '@azure/msal-browser';
import { msalInstance } from './config/msalConfig';
import { UserbackProvider } from '@userback/react';

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
    if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        (event.payload as AuthenticationResult).account
    ) {
        msalInstance.setActiveAccount((event.payload as AuthenticationResult).account);
    }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <MsalProvider instance={msalInstance}>
                <IntlProvider locale="en">
                    <Router history={history}>
                        {process.env.NODE_ENV !== 'development' &&
                            <UserbackProvider token={process.env.REACT_APP_USERBACK_TOKEN} options={{email: msalInstance.getActiveAccount()?.username, name: msalInstance.getActiveAccount()?.name}}>
                                <App/>
                            </UserbackProvider>
                        }
                        {process.env.NODE_ENV === 'development' &&
                            <App/>
                        }
                    </Router>
                </IntlProvider>
            </MsalProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
