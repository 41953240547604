import React, { forwardRef} from 'react';
import ReactMarkdown from 'react-markdown';
import './MilestoneBlock.scss';
import GradientLabel from './GradientLabel';
import Text from '../../components/Text';
import { getMilestoneById } from '../../utilities/milestonesUtils';
import { WebSocketMessage } from '../../utilities/transformMessages';
import { MilestoneId } from '../../types';

interface MilestoneBlockProps {
    message: WebSocketMessage;
    completed?: boolean;
}

const MilestoneBlock = forwardRef<HTMLDivElement, MilestoneBlockProps>(({message, completed}, ref) => {
    const milestone = getMilestoneById(message.type as MilestoneId);

    return (
        <div className="milestone-block-outer-wrapper" ref={ref}>
            <div className="milestone-block-inner-wrapper">
                <GradientLabel milestoneLabel={milestone?.label ? milestone?.label : completed ? 'Session completed' : ''}/>
                {!completed ?
                    <div className="milestone-block-text-wrapper">
                        <Text
                            className="milestone-block-milestone-text w-100"><ReactMarkdown>{message?.content}</ReactMarkdown></Text>
                        <div className="milestone-block-congratulation">
                            {`Outstanding! You have successfully defined your ${milestone?.label.toLowerCase() || 'goal'}!`}
                        </div>
                    </div>
                    :
                    <div className="milestone-block-text-wrapper">
                        <div className="milestone-block-milestone-text"><Text
                            label="eth.milestone_block.complete.content" message="You have completed your session!"/>
                        </div>
                        <div className="milestone-block-congratulation">
                            <Text label="eth.milestone_block.complete.subtext" message="Awesome job today!"/>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
});

export default MilestoneBlock;
