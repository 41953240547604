import React, { forwardRef, useEffect, useRef, useState } from 'react';
import './Message.scss';
import Text from '../../../components/Text';
import Icon from '../../../components/Icon';
import { WebSocketMessage } from '../../../utilities/transformMessages';
import { useAppSelector } from '../../../store';
import ReactMarkdown from 'react-markdown';
import Button from '../../../components/Button';

type Props = {
    message: WebSocketMessage,
    isLastMessage: boolean,
    sendMessage: ( message: string, show: boolean) => void
    deleteTurn?: (index: number) => void
};

const Message = forwardRef<HTMLDivElement, Props>(({ message, isLastMessage, sendMessage, deleteTurn }, ref) => {
    const userState = useAppSelector(state => state.auth.user);
    const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
    const [inputValue, setInputValue] = useState(message.content?.replace(/<br\s?\/?>/g,"\n") || '');
    const textareaRef = useRef(null);
    const [error, setError] = useState('');

    const handleEditSave = () => {
        if (inputValue === '') {
            setError("Please enter message");
            return;
        }

        if (inputValue !== '' && inputValue !== message.content) {
            deleteTurn(-1);
            sendMessage(inputValue, false);
        }

        setIsEditModeEnabled(false);
    };

    const adjustHeight = () => {
        const textarea = textareaRef.current;

        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };

    const handleInput = (e) => {
        setInputValue(e.target.value);
        adjustHeight();

        if (error) {
            setError('');
        }
    };

    useEffect(() => {
        if (isEditModeEnabled) {
            adjustHeight();
        }
    }, [isEditModeEnabled]);


    return (
        <div className="position-relative" ref={ref}>
            {message.content && message?.isUser && (
                <div className={`message message__user d-flex w-auto ${isLastMessage ? 'editable' : ''}`}>
                    <div className="message__user-icon">
                        <span>{userState?.name[0]}</span>
                    </div>
                    {!isLastMessage &&
                        <Text className="w-100">
                            <div dangerouslySetInnerHTML={{__html: message.content}}/>
                        </Text>
                    }
                    {isLastMessage && !isEditModeEnabled &&
                        <div className="w-100">
                            <Text className="w-100">
                                <div dangerouslySetInnerHTML={{__html: message.content}}/>
                            </Text>
                            <div className="message__controls cursor-pointer" onClick={() => {setIsEditModeEnabled(!isEditModeEnabled); adjustHeight()}}>
                                <Icon type="edit"/>
                                <Text message="Edit" label="eth.message.edit"/>
                            </div>
                        </div>
                    }
                    {isLastMessage && isEditModeEnabled &&
                        <div className="w-100" >
                            <textarea
                                className="text-input w-100"
                                value={inputValue}
                                onChange={(e) => handleInput(e)}
                                ref={textareaRef}
                                rows={1}
                            />
                            <div className="message__buttons">
                                {error ? <Text error>{error}</Text> : null}
                                <Button style="secondary" text="Cancel" size="small"
                                        onClick={() => {
                                            setIsEditModeEnabled(false);
                                            setError('');
                                            setInputValue(message.content?.replace(/<br\s?\/?>/g,"\n") || '');
                                        }}/>
                                <Button
                                    text="Save"
                                    icon={<Icon type="check" className="check-icon"/>}
                                    size="small"
                                    onClick={handleEditSave}
                                />
                            </div>
                        </div>
                    }
                </div>
            )}
            {message.content && !message?.isUser && (
                <div className="message message__reply d-flex w-auto">
                    <Icon type="origami_small" className="desktop-message-icon" />
                    <img src={'/mob-bird-chat.png'} className="mob-message-icon" alt="bird" />
                    <Text className="w-100">
                        <ReactMarkdown>{message.content}</ReactMarkdown>
                    </Text>
                </div>
            )}
        </div>
    );
});

export default Message;
