import { useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useGSAP } from "@gsap/react";

const useApproachAnimation = () => {
    const approachRightRef = useRef<HTMLDivElement | null>(null);
    const approachLeftRef = useRef<HTMLDivElement | null>(null);


    return {
        approachRightRef,
        approachLeftRef
    };
};

export default useApproachAnimation;
