import React, { useState } from 'react';
import {
    ApproachPoint,
    ApproachPointUnChecked,
    FirstIcon,
    MobileApproachOne,
    MobileApproachSecond,
    MobileApproachThird,
    SecondIcon,
    ThirdIcon,
} from '../../../../assets/img';
import useApproachAnimation from '../../../../hooks/useAppoach';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import './Approach.scss';

const cardData = [
    {
        icon: <FirstIcon />,
        title: 'Challenges are increasing',
        text: 'Rising employee expectations, heavier workloads, and faster decision-making—leadership is becoming more demanding, even at ETH.',
    },
    {
        icon: <SecondIcon />,
        title: 'Traditional methods can’t keep up',
        text: 'Traditional methods often work with outdated strategies, are not sufficiently tailored to your individual questions and are not there when you really need them.',
    },
    {
        icon: <ThirdIcon />,
        title: 'AI can help',
        text: 'ETH’s Leadership Companion provides real-time, personalized insights and actionable guidance tailored to your individual needs, offering a dedicated space for self-reflection and growth.',
    },
];
const cardMobileData = [
    {
        icon: <MobileApproachOne />,
        title: 'Challenges are increasing',
        text: 'Rising employee expectations, heavier workloads, and faster decision-making—leadership is becoming more demanding, even at ETH.',
    },
    {
        icon: <MobileApproachSecond />,
        title: 'Traditional methods can’t keep up',
        text: 'Traditional methods often work with outdated strategies, are not sufficiently tailored to your individual questions and are not there when you really need them.',
    },
    {
        icon: <MobileApproachThird />,
        title: 'AI can help',
        text: 'ETH’s Leadership Companion provides real-time, personalized insights and actionable guidance tailored to your individual needs, offering a dedicated space for self-reflection and growth.',
    },
];

const Approach = () => {
    const { approachLeftRef, approachRightRef } = useApproachAnimation();
    const [activeSlide, setActiveSlide] = useState<number>(0);

    return (
        <div className="approach-container">
            <div className="approach-left-container" ref={approachLeftRef}>
                <div className="approach-text-box">
                    <h2 className="approach-leadership">
                        <span className="approach-1">Why</span>
                        <span className="approach-highlight">
                            <span className="approach-2">rethink</span>
                            <span className="approach-3"> leadership</span>
                        </span>
                        <br />
                        <span className="approach-highlight-1">
                            <span className="approach-4"> development?</span>
                        </span>
                    </h2>

                    {cardData.map((card, index) => (
                        <div className={`approach-card approach-card-animation-${index}`} key={index}>
                            <div>{card.icon}</div>
                            <div className="approach-bold-text">{card.title}</div>
                            <div className="approach-sub-text">{card.text}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="approach-image-container" ref={approachRightRef}>
                <img src="/women-office.png" alt="women" className="approach-image" />
            </div>

            {/* Mobile version */}
            <div className="approach-container-mob">
                <div className="approach-text-box-mob">
                    <h2 className="approach-leadership-mob">
                        Why
                        <span className="approach-highlight-mob">rethink leadership</span>
                        <div className="approach-highlight-mob-1"> development?</div>
                    </h2>

                    <div className="mobile-card-container">
                        <Swiper
                            pagination={false}
                            modules={[Pagination]}
                            onActiveIndexChange={swiper => setActiveSlide(swiper.activeIndex)}
                        >
                            {cardMobileData.map((card, index) => (
                                <SwiperSlide key={index}>
                                    <div className="approach-card-mob">
                                        <div className="approach-icon">{card.icon}</div>
                                        <div className="approach-bold-text-mob">{card.title}</div>
                                        <div className="approach-sub-text-mob">{card.text}</div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="points">
                            {activeSlide === 0 ? <ApproachPoint /> : <ApproachPointUnChecked />}
                            {activeSlide === 1 ? <ApproachPoint /> : <ApproachPointUnChecked />}
                            {activeSlide === 2 ? <ApproachPoint /> : <ApproachPointUnChecked />}
                        </div>
                    </div>
                    <div className="approach-image-container-mob">
                        <img src="/women-mobile.png" alt="women" className="approach-image-mob" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Approach);
