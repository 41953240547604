/* eslint-disable react/style-prop-object */
import React from 'react';
import './MilestoneSetter.scss';
import Button from '../../Button';
import Icon from '../../Icon';
import { MilestoneId } from '../../../types';
import { getMilestoneById } from '../../../utilities/milestonesUtils';

interface MilestoneSetterProps {
    milestoneType: MilestoneId;
    milestoneText: string;
    setIsSetMilestone: (value: boolean) => void;
    updateMilestone: (milestone: any) => void;
    cancelMilestone: () => void;
    textareaRef: any;
}

const MilestoneSetter = ({ milestoneType, milestoneText, setIsSetMilestone, updateMilestone, cancelMilestone, textareaRef }: MilestoneSetterProps) => {
    const [isEditing, setIsEditing] = React.useState(false);
    const [milestoneTextState, setMilestoneTextState] = React.useState(milestoneText);
    const [milestoneTextSaved, setMilestoneTextSaved] = React.useState(milestoneText);

    const handleSave = async () => {
        try {
            updateMilestone({ milestoneType, milestoneText: milestoneTextSaved });
            textareaRef.current.focus();
        } catch (error) {
            console.error('Error saving milestone', error);
        }
    };

    const handleCancelMilestone = async () => {
        try {
            cancelMilestone();
            setIsSetMilestone(false);
        } catch (error) {
            console.error('Error canceling milestone', error);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setMilestoneTextState(milestoneTextSaved);
    };

    const handleEditSave = () => {
        setMilestoneTextSaved(milestoneTextState);
        setIsEditing(false);
    };

    const getMilestoneLabel = (milestoneType: MilestoneId) => {
        const milestone = getMilestoneById(milestoneType);
        if (milestone?.label === 'Session Goal') {
            return 'goal';
        }
        return milestone?.label.toLowerCase();
    };

    return (
        <div className="milestone-setter-outer-wrapper">
            <div className="milestone-setter-wrapper">
                <div className="milestone-setter">
                    <div className="milestone-setter__left">
                        {isEditing ? (
                            <textarea
                                className="milestone-setter__input"
                                value={milestoneTextState}
                                onChange={e => setMilestoneTextState(e.target.value)}
                                autoFocus
                            />
                        ) : (
                            <>
                                <div className="milestone-setter__title">
                                    {`Set as ${getMilestoneLabel(milestoneType)}?`}
                                </div>
                                <div className="milestone-setter__line">{milestoneTextSaved}</div>
                            </>
                        )}
                    </div>
                    <div className="milestone-setter__right">
                        {isEditing ? (
                            <>
                                <Button
                                    style="secondary"
                                    text="Cancel"
                                    className="milestone-cancel-btn"
                                    size="small"
                                    btnClass="milestone-cancel-text"
                                    onClick={handleCancel}
                                />
                                <Button
                                    text="Save"
                                    className="milestone-save-btn"
                                    icon={<Icon type="check" className="check-icon" />}
                                    size="small"
                                    btnClass="milestone-save-text"
                                    onClick={handleEditSave}
                                />
                            </>
                        ) : (
                            <>
                                <div className="right-btn">
                                    <Button
                                        style="secondary"
                                        text="Cancel"
                                        size="small"
                                        className="milestone-cancel-btn"
                                        btnClass="milestone-cancel-text"
                                        onClick={handleCancelMilestone}
                                    />
                                    <Button
                                        style="secondary"
                                        text="Edit"
                                        className="milestone-edit-btn"
                                        btnClass="milestone-edit-text"
                                        size="small"
                                        onClick={() => setIsEditing(true)}
                                    />
                                </div>
                                <Button
                                    text={`Set as my ${getMilestoneLabel(milestoneType)}`}
                                    icon={<Icon type="check" className="check-icon" />}
                                    size="small"
                                    className="milestone-save-btn"
                                    btnClass="milestone-save-text"
                                    btnIcon="milestone-save-icon"
                                    onClick={handleSave}
                                />
                            </>
                        )}
                    </div>
                </div>
                <div className="milestone-border" />
            </div>
        </div>
    );
};

export default MilestoneSetter;
