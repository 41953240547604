import { ScrollTrigger } from "gsap/all";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useRef, useState } from "react";

const useLeadershipAnimation = (
) => {

    const [isAnimationInProgress, setIsAnimationInProgress] = useState(true);
    const [openCard, setOpenCard] = useState<number | undefined>(undefined);
    const textBoxRefs = useRef<(HTMLDivElement | null)[]>([]);
    const textBoxMobRefs = useRef<(HTMLDivElement | null)[]>([]);
    const companionFirstRef = useRef<HTMLDivElement | null>(null);
    const companionSecondRef = useRef<HTMLDivElement | null>(null);
    const companionThirdRef = useRef<HTMLDivElement | null>(null);
    const companionSpanRef = useRef<HTMLSpanElement | null>(null);
    const companionTabletRef = useRef<HTMLDivElement | null>(null);
    const totalLength = textBoxRefs.current.length;
    const totalMobLength = textBoxMobRefs.current.length;

    const handleCard = (cardNo: number) => {
        if (openCard === cardNo) {
            textBoxRefs.current.forEach((card, index) => {
                if (card) gsap.to(card, { y: index * 70 });
            });
            textBoxMobRefs.current.forEach((card, index) => {
                if (card) gsap.to(card, { y: index * 70 });
            });
            setOpenCard(undefined);
        } else {
            setOpenCard(cardNo);

            textBoxRefs.current.forEach((card, index) => {
                if (card) {
                    if (index <= cardNo) {
                        gsap.to(card, { y: index * 70 });
                    } else {
                        gsap.to(card, { y: index * 70 + 140 });
                    }
                }
            });
            textBoxMobRefs.current.forEach((card, index) => {
                if (card) {
                    if (index <= cardNo) {
                        gsap.to(card, { y: index * 70 });
                    } else {
                        gsap.to(card, { y: index * 70 + 180 });
                    }
                }
            });
        }
    };

    useGSAP(() => {
        const mm = gsap.matchMedia();
        const tl = gsap.timeline();

        mm.add('(min-width:450px)', () => {
            gsap.set('.expect-left-container', { display: 'flex' })
            gsap.set('.expect-right-container', { display: 'flex' })
            gsap.set('.expect-left-container-mob', { display: 'none' })
            gsap.set('.expect-right-container-mob', { display: 'none' })
            gsap.set('.features-title', { display: 'block' })
            gsap.set('.features-title-mobile', { display: 'none' })
            gsap.set('.companion-bird-noise-mob', { display: 'none' })
            gsap.set('.companion-bird-noise', { display: 'block' })
            gsap.set(companionFirstRef.current, { display: 'block' })
            gsap.set(
                '.companion-title-mob',
                { display: 'none' },
            )

            gsap.set(
                '.tab-container',
                { display: 'none' },
            )
            gsap.set(
                '.companion-title',
                { display: 'block' },
            )

            gsap.set(
                '.companion-tablet-mob',
                { display: 'none' },
            )
            gsap.set(
                '.companion-tablet',
                { display: 'block' },
            )

            gsap.set(
                '.approach-container-mob',
                { display: 'none' }
            )
            gsap.set(
                '.approach-left-container',
                { display: 'flex' }
            )
            gsap.set(
                '.approach-left-container-mob',
                { display: 'none' }
            )
            gsap.set(
                '.approach-image-container',
                { display: 'flex' }
            )
            gsap.set(
                '.approach-image-container-mob',
                { display: 'none' }
            )

            tl.fromTo(
                '.first-container',
                { top: '70%', left: '50%', opacity: 0, display: 'inline-block' },
                { top: '50%', left: '50%', opacity: 1, duration: 1 }, 0
            ).fromTo(
                '.feature-1',
                { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                { y: 0, opacity: 1, duration: 1 }, 0
            ).fromTo(
                '.feature-2',
                { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                { y: 0, opacity: 1, duration: 1.2 }, 0
            ).fromTo(
                '.features-span-1',
                { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                { y: 0, opacity: 1, duration: 1.4 }, 0
            ).fromTo(
                '.features-span-2',
                { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                { y: 0, opacity: 1, duration: 1.6 }, 0
            ).fromTo(
                '.feature-3',
                { y: 110, opacity: 0, display: 'inline-block', padding: '0 5px' },
                { y: 0, opacity: 1, duration: 1 }, 0
            ).fromTo(
                '.feature-4',
                { y: 110, opacity: 0, display: 'inline-block', padding: '0 5px' },
                { y: 0, opacity: 1, duration: 1.2 }, 0
            ).fromTo(
                '.feature-5',
                { y: 110, opacity: 0, display: 'inline-block', padding: '0 5px' },
                { y: 0, opacity: 1, duration: 1.4 }, 0
            ).fromTo(
                '.feature-6',
                { y: 110, opacity: 0, display: 'inline-block', padding: '0 5px' },
                { y: 0, opacity: 1, duration: 1.6 }, 0
            ).fromTo(
                '.features-span',
                { backgroundPosition: "0%" },
                { backgroundPosition: "-100%" },
            )
                .fromTo(
                    '.first-container',
                    { top: '50%', left: '50%', opacity: 1, display: 'flex' },
                    { top: '30%', left: '50%', opacity: 0, duration: 2 }, 4
                ).fromTo(
                    '.companion-container',
                    { top: '100%', left: '50%', opacity: 0, display: 'flex' },
                    { top: '50%', left: '50%', opacity: 1, duration: 5 }, 4
                ).fromTo(
                    companionTabletRef.current,
                    { y: 100, width: '120%', opacity: 0 },
                    { y: 30, width: '50%', opacity: 1, duration: 2 }, 6
                ).fromTo(
                    '.play-button',
                    { opacity: 0 },
                    { opacity: 1, duration: 2 }, 6
                ).fromTo(
                    '.companion-1',
                    { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1 }, 6
                ).fromTo(
                    '.companion-2',
                    { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.2 }, 6
                ).fromTo(
                    '.tablet-bird',
                    { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.4 }, 6
                ).fromTo(
                    '.companion-3',
                    { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.6 }, 6
                ).fromTo(
                    '.companion-4',
                    { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.8 }, 6
                ).fromTo(
                    '.companion-5',
                    { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1 }, 7
                ).fromTo(
                    '.companion-6',
                    { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.2 }, 7
                ).fromTo(
                    '.companion-7',
                    { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.4 }, 7
                ).fromTo(
                    '.companion-8',
                    { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.6 }, 7
                ).fromTo(
                    '.companion-9',
                    { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1 }, 8
                ).fromTo(
                    '.companion-10',
                    { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.2 }, 8
                ).fromTo(
                    '.companion-11',
                    { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.4 }, 8
                ).fromTo(
                    companionSpanRef.current,
                    { backgroundPosition: "0%" },
                    { backgroundPosition: "-100%", duration: 2 }
                ).to(companionTabletRef.current, {
                    width: '30%', duration: 2
                }).fromTo(
                    '.companion-container',
                    { top: '50%', left: '50%', opacity: 1, display: 'flex' },
                    { top: '-100%', left: '50%', opacity: 0, duration: 5 }, 16
                ).fromTo(
                    '.approach-container',
                    { top: '100%', left: '50%', opacity: 0, display: 'flex' },
                    { top: '50%', left: '50%', opacity: 1, duration: 5 }, 16
                ).fromTo(
                    '.approach-left-container',
                    { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1 }, 17
                ).fromTo(
                    '.approach-1',
                    { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1 }, 17
                ).fromTo(
                    '.approach-2',
                    { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.2 }, 17
                ).fromTo(
                    '.approach-3',
                    { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.4 }, 17
                ).fromTo(
                    '.approach-4',
                    { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.6 }, 17
                ).fromTo(
                    '.approach-5',
                    { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.8 }, 17
                ).fromTo(
                    '.approach-6',
                    { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 2 }, 17
                ).fromTo(
                    '.approach-image-container',
                    { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1 }, 17
                ).fromTo(
                    ".approach-highlight",
                    { backgroundPosition: "0%" },
                    { backgroundPosition: "-100%" }
                ).fromTo(
                    ".approach-highlight-1",
                    { backgroundPosition: "0%" },
                    { backgroundPosition: "-100%" }
                ).fromTo(
                    '.approach-card-animation-0',
                    { x: '-100%', opacity: 0, display: 'block', },
                    { x: 0, opacity: 1, duration: 1 }
                ).fromTo(
                    '.approach-card-animation-1',
                    { x: '-100%', opacity: 0, display: 'block' },
                    { x: 0, opacity: 1, duration: 1, delay: 1 }
                ).fromTo(
                    '.approach-card-animation-2',
                    { x: '-100%', opacity: 0, display: 'block', },
                    { x: 0, opacity: 1, duration: 1, delay: 2 }
                ).fromTo(
                    '.approach-container',
                    { top: '50%', left: '50%', opacity: 1 },
                    { top: '-100%', left: '50%', opacity: 0, duration: 5 }, 30).fromTo(
                        '.expect-container',
                        { top: '100%', left: '50%', opacity: 0, display: 'flex' },
                        { top: '50%', left: '50%', opacity: 1, duration: 5 }, 30
                    ).fromTo('.expect-1', { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' }, { y: 0, opacity: 1, duration: 2 }, 33)
                .fromTo('.expect-2', { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' }, { y: 0, opacity: 1, duration: 2.2 }, 33)
                .fromTo('.expect-3', { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' }, { y: 0, opacity: 1, duration: 2.6 }, 33)
                .fromTo('.expect-4', { y: 300, opacity: 0, display: 'inline-block', padding: '0 5px' }, { y: 0, opacity: 1, duration: 3 }, 33)
                .fromTo(
                    '.expect-highlight',
                    { backgroundPosition: "0%" },
                    {
                        backgroundPosition: "-100%",
                    }
                ).to({}, { duration: 2 }, 34)

            textBoxRefs.current.forEach((card, index) => {
                if (card) {
                    tl.fromTo(
                        card,
                        {
                            y: 100 + index * 100 + 250,
                            rotation: index % 2 === 0 ? -25 : 30,
                        },
                        {
                            y: (index * 70) + (index === 0 ? 0 : 1 * index),
                            rotation: 0,
                            duration: 2,
                            onStart: () => {
                                if (index === 0) setIsAnimationInProgress(true);
                            },
                            onComplete: () => {
                                console.log({ totalLength, index })
                                if (totalLength - 1 === index + 1) setIsAnimationInProgress(false);
                            },
                        }, 36 + index * 2
                    ).fromTo(card, {
                        opacity: 0,
                    }, {
                        opacity: 1, duration: 2
                    }, 36 + index * 2);
                }
            });
        })

        mm.add('(max-width:450px)', () => {
            gsap.set(
                '.companion-title-mob',
                { display: 'block' },
            )
            gsap.set(
                '.tab-container',
                { display: 'flex' },
            )
            gsap.set(
                '.companion-title',
                { display: 'none' },
            )
            gsap.set(
                '.companion-tablet-mob',
                { display: 'block', margin: '0' },
            )
            gsap.set(
                '.companion-tablet',
                { display: 'none' },
            )
            gsap.set(
                '.tablet-container',
                { display: 'none' },
            )
            gsap.set('.companion-bird-noise-mob', { display: 'block' })
            gsap.set('.companion-bird-noise', { display: 'none' })
            gsap.set('.companion-container', { gap: '50px' })
            gsap.set('.tab-container', { display: 'flex' })

            gsap.to(
                ".features",
                {
                    backgroundImage: 'url(/mobile-feature.webp)'
                }
            )

            gsap.set(
                '.approach-left-container',
                { display: 'none' }
            )
            gsap.set(
                '.approach-left-container-mob',
                { display: 'flex' }
            )
            gsap.set(
                '.approach-image-container',
                { display: 'none' }
            )
            gsap.set(
                '.approach-image-container-mob',
                { display: 'block' }
            )
            gsap.set(
                '.approach-container-mob',
                { display: 'flex' }
            )

            gsap.set(
                '.approach-container',
                { alignItems: 'start' }
            )

            gsap.set('.expect-left-container', { display: 'none' })
            gsap.set('.expect-right-container', { display: 'none' })

            gsap.set('.expect-left-container-mob', { display: 'flex' })
            gsap.set('.expect-right-container-mob', { display: 'flex' })
            gsap.set('.expect-container-mob', { display: 'block' })
            gsap.set('.expect-container', { height: 'fit-content' })

            tl.to('.features-title', { display: 'none' })
                .to('.features-title-mobile', { display: 'block' }).fromTo(
                    '.feature-subtitle-first-mob',
                    { y: 100, opacity: 0 },
                    { y: 0, opacity: 1, duration: 1 }
                ).fromTo(
                    '.feature-1',
                    { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1 }, 0
                ).fromTo(
                    '.feature-2',
                    { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.2 }, 0
                ).fromTo(
                    '.features-span-1',
                    { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.4 }, 0
                ).fromTo(
                    '.features-span-2',
                    { y: 100, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.6 }, 0
                )
                .fromTo(
                    '.feature-3',
                    { y: 110, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1 }, 0
                ).fromTo(
                    '.feature-4',
                    { y: 110, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.2 }, 0
                ).fromTo(
                    '.feature-5',
                    { y: 110, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.4 }, 0
                )
                .fromTo(
                    '.feature-6',
                    { y: 110, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.6 }, 0
                )
                .fromTo(
                    '.feature-subtitle-second-mob',
                    { y: 110, opacity: 0 },
                    { y: 0, opacity: 1, duration: 1 }
                )
                .fromTo(
                    '.features-span-1-mob',
                    { backgroundPosition: "0%" },
                    { backgroundPosition: "-100%" }
                )
                .fromTo(
                    '.features-span-2-mob',
                    { backgroundPosition: "0%" },
                    { backgroundPosition: "-100%" },
                ).fromTo(
                    '.features-span-2-mob',
                    { backgroundPosition: "-100%" },
                    { backgroundPosition: "-100%", duration: 2 }
                ).fromTo(
                    '.first-container',
                    { top: '50%', opacity: 1, display: 'flex', duration: 2 },
                    { top: '-100%', opacity: 0, duration: 2 }
                ).fromTo(
                    '.companion-container',
                    { top: '100%', opacity: 0, display: 'flex', duration: 2 },
                    { top: '50%', opacity: 1, duration: 2 }
                ).fromTo(
                    '.companion-title-mob',
                    { y: 300, opacity: 0, display: 'block' },
                    { y: 0, opacity: 1, duration: 2 }
                ).fromTo(
                    '.companion-span-mob',
                    { backgroundPosition: "0%" },
                    { backgroundPosition: "-100%", duration: 4 }
                ).fromTo(
                    '.companion-subtitle-first-mob',
                    { y: 300, opacity: 0 },
                    { y: 0, opacity: 1, duration: 2 },
                    0
                )
                .fromTo(
                    '.companion-subtitle-second-mob',
                    { y: 300, opacity: 0 },
                    { y: 0, opacity: 1, duration: 2 },
                    0
                )
                .fromTo(
                    '.companion-subtitle-second-mob',
                    { y: 300, opacity: 0 },
                    { y: 0, opacity: 1, duration: 2 },
                    0
                )
                .fromTo(
                    companionSpanRef.current,
                    { backgroundPosition: "0%" },
                    { backgroundPosition: "-100%", duration: 4 }
                )
                .fromTo(
                    '.companion-bird-',
                    { y: 100, opacity: 0 },
                    { y: 80, opacity: 1, duration: 4 },
                    0
                ).fromTo(
                    '.companion-container',
                    { top: '50%', left: '50%', opacity: 1, display: 'flex', duration: 2 },
                    { top: '-100%', left: '50%', opacity: 0, duration: 2 }
                ).fromTo(
                    '.approach-container',
                    { top: '100%', left: '50%', opacity: 0, display: 'flex', duration: 2 },
                    { top: '0%', left: '50%', opacity: 1, duration: 2 }
                ).fromTo(
                    '.approach-text-box-mob',
                    { y: 100, opacity: 0 },
                    { y: 0, opacity: 1, duration: 1 }
                ).fromTo(
                    ".approach-highlight-mob",
                    { backgroundPosition: "0%" },
                    { backgroundPosition: "-100%", duration: 5 }
                ).fromTo(
                    ".approach-highlight-mob-1",
                    { backgroundPosition: "0%" },
                    { backgroundPosition: "-100%", duration: 5 }
                ).to({}, { duration: 10 }).fromTo(
                    '.approach-container',
                    { top: '0%', left: '50%', opacity: 1, display: 'flex', duration: 2 },
                    { top: '-100%', left: '50%', opacity: 0, duration: 2 }
                ).fromTo(
                    '.expect-container',
                    { top: '100%', left: '50%', opacity: 0, display: 'flex', duration: 2 },
                    { top: '0%', left: '50%', opacity: 1, duration: 2 }
                ).fromTo(
                    '.expect-highlight-mob',
                    { backgroundPosition: "0%" },
                    {
                        backgroundPosition: "-100%",
                    }
                );

            textBoxMobRefs.current.forEach((card, index) => {
                if (card) {
                    tl.fromTo(
                        card,
                        {
                            y: 100 + index * 100 + 250,
                            rotation: index % 2 === 0 ? -25 : 30,
                        },
                        {
                            y: (index * 70) + (index === 0 ? 0 : 1 * index),
                            rotation: 0,
                            duration: 2,
                            onStart: () => {
                                if (index === 0) setIsAnimationInProgress(true);
                            },
                            onComplete: () => {
                                if (totalMobLength === index + 1) setIsAnimationInProgress(false);
                            },
                        }
                    ).fromTo(card, {
                        opacity: 0,
                    }, {
                        opacity: 1,
                    }, index * 2);;
                }
            });
        })

        ScrollTrigger.create({
            animation: tl,
            trigger: ".leadership-container",
            toggleActions: "play reset reverse none",
            start: "top top",
            end: "+=1000%",
            pin: true,
            scrub: true,
            pinSpacing: true,
        });
    }, []);

    return {
        companionFirstRef,
        companionSecondRef,
        companionThirdRef,
        companionSpanRef,
        companionTabletRef,
        textBoxRefs,
        textBoxMobRefs,
        handleCard,
    };
};

export default useLeadershipAnimation;
