import React, { useEffect, useCallback, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { useDispatch } from 'react-redux';
import '../../App.css';
import { authActions, sessionActions } from '../../actions';
import { loginRequest } from '../../config/msalConfig';
import { getSessions } from '../../utilities/apiService';
import { useAppSelector } from '../../store';

interface AuthGuardProps {
    children: React.ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
    const initialMessage = useAppSelector(state => state.session.message);
    const [isChecking, setIsChecking] = useState(true);
    const [updatedToken, setUpdatedToken] = useState('');
    const dispatch = useDispatch();
    const { accounts, instance, inProgress } = useMsal();

    const checkAuth = useCallback(async () => {
        if (!accounts || accounts.length === 0) {
            setIsChecking(false);
            return;
        }

        const acc = accounts[0];
        try {
            await instance.initialize();
            const { accessToken, account } = await instance.acquireTokenSilent({
                account: acc,
                scopes: loginRequest.scopes,
            });
            authActions.updateAccessToken(accessToken)(dispatch);
            localStorage.setItem('authToken', accessToken);
            setUpdatedToken(accessToken);

            const user = {
                name: account?.name || "",
                email: account?.username || "",
            };
            authActions.setUser(user)(dispatch);
            setIsChecking(false);
        } catch (error) {
            setIsChecking(false);
            console.error('Error acquiring token', error);
        }
    }, [accounts, dispatch, instance]);

    const fetchSessions = useCallback(async () => {
        try {
            const sessions = await getSessions();
            if (sessions && sessions.length > 0 && !initialMessage) {
                const active = sessions.find(s => s.hash_id === localStorage.getItem('activeSessionId'));
                if (active) {
                    sessionActions.setActiveSession(active)(dispatch);
                }
            }
            sessionActions.loadSessions(sessions)(dispatch);
            setIsChecking(false);
        } catch (error) {
            setIsChecking(false);
            console.error('Error fetching sessions:', error);
        }
    }, [dispatch, initialMessage]);

    useEffect(() => {
        if (inProgress !== InteractionStatus.None) {
            return;
        }
        checkAuth();
    }, [checkAuth, inProgress]);

    useEffect(() => {
        if (updatedToken && updatedToken.length > 0 && !isChecking) {
            fetchSessions();
        }
    }, [fetchSessions, updatedToken, isChecking]);

    if (isChecking) {
        return <div>Loading...</div>;
    } else if (!accounts.length && window.location.pathname !== '/login') {
        return <Navigate to="/login" />;
    } else return <>{children}</>;
};

export default AuthGuard;
