import React from 'react';
import './SessionSummary.scss';
import Text from '../../../components/Text';
import ReactMarkdown from 'react-markdown';


type Props = {
    summaryText: string;
    summaryTitle: string;
};

const SessionSummary = ({ summaryText, summaryTitle }: Props) => {

    return (
        <div className="session-summary">
            <Text h4 message="Session summary"/>
            <div className="session-summary__wrapper mt-3">
                <Text h5 bold message={summaryTitle}/>
                <Text left className="mt-1" lightBlack80>
                    <ReactMarkdown>{summaryText}</ReactMarkdown>
                </Text>
            </div>
        </div>
    );
};

export default SessionSummary;
