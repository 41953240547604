import { sessionConstants } from '../constants';
import { StoreDispatchType } from '../store';
import { Session, SessionSummary } from '../types';


export const sessionActions = {
  sendMessage, loadSessions, setActiveSession, updatePrevActiveSession,
  addSession, setSources, setLinks, setSessionSummary, setSessionTitle, setActiveSessionOverview, setActiveSessionResource
};

function sendMessage(message: string) {
  return (dispatch: StoreDispatchType) => {
    dispatch((data => {
      return { type: sessionConstants.SET_MESSAGE, data }
    })({ message }));
  };
}

function setActiveSession(session: Session) {
  return (dispatch: StoreDispatchType) => {
    dispatch((data => {
      return { type: sessionConstants.SET_ACTIVE_SESSION, data }
    })({ session }));
  };
}

function loadSessions(sessions: Array<Session>) {
  return (dispatch: StoreDispatchType) => {
    dispatch((data => {
      return { type: sessionConstants.SET_SESSIONS, data }
    })({ sessions }));
  };
}

function updatePrevActiveSession(session: Session) {
  return (dispatch: StoreDispatchType) => {
    dispatch((data => {
      return { type: sessionConstants.UPDATE_PREV_ACTIVE_SESSION, data }
    })({ session }));
  };
}

function addSession(session: Session) {
  return (dispatch: StoreDispatchType) => {
    dispatch((data => {
      return { type: sessionConstants.ADD_SESSION, data }
    })({ session }));
  };
}

function setSources(sources: Array<string>) {
  return (dispatch: StoreDispatchType) => {
    dispatch((data => {
      return { type: sessionConstants.SET_SOURCES, data }
    })({ sources }));
  };
}

function setSessionSummary(sessionSummary: SessionSummary) {
  return (dispatch: StoreDispatchType) => {
    dispatch((data => {
      return { type: sessionConstants.SET_SESSION_SUMMARY, data }
    })({ sessionSummary }));
  };
}

function setSessionTitle(sessionTitle: string) {
  return (dispatch: StoreDispatchType) => {
    dispatch((data => {
      return { type: sessionConstants.SET_SESSION_TITLE, data }
    })({ sessionTitle }));
  };
}

function setLinks(links: Array<string>) {
  return (dispatch: StoreDispatchType) => {
    dispatch((data => {
      return { type: sessionConstants.SET_LINKS, data }
    })({ links }));
  };
}

function setActiveSessionOverview(sessionOverview: boolean) {
  return (dispatch: StoreDispatchType) => {
    dispatch((data => {
      return { type: sessionConstants.SET_ACTIVE_SESSION_OVERVIEW, data }
    })({ sessionOverview }));
  };
}
function setActiveSessionResource(sessionResource: boolean) {
  return (dispatch: StoreDispatchType) => {
    dispatch((data => {
      return { type: sessionConstants.SET_ACTIVE_SESSION_RESOURCE, data }
    })({ sessionResource }));
  };
}