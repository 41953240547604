import React from 'react';
import Icon from '../../../components/Icon';
import Text from '../../../components/Text';
import './SlidingCards.scss';

export const DashboardCard = ({ icon, message, desc }: { icon: string; message: string; desc: string }) => {
    return (
        <div className="dashcard-container">
            <div className="benefits-icon-wrapper position-absolute">
                <Icon type={icon} className="benefits-icon" />
            </div>
            <Text h5 left label="eth.dashboard.benefits.benefit1.title" className="top-text" message={message} />
            <Text
                left
                className="eth-space-top-nano bottom-text"
                label="eth.dashboard.benefits.benefit1.text"
                message={desc}
            />
        </div>
    );
};
