import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import './SessionModal.scss';
import {
    CheckedIcon,
    CheckIcon,
    ClipIcon,
    CrossIcon,
    Eight,
    EscKey,
    Five,
    Four,
    Nine,
    One,
    Seven,
    Six,
    Ten,
    Three,
    Two,
    UnCheckedIcon,
} from '../../../assets/img';
import { submitFeedback } from '../../../utilities/apiService';
import { FeedbackPayload } from '../../../types';

const numbersIcons = [<One />, <Two />, <Three />, <Four />, <Five />, <Six />, <Seven />, <Eight />, <Nine />, <Ten />];

export default function FeedbackModal({
    isOpen,
    onClose,
    setIsThankYou,
}: {
    isOpen: boolean;
    onClose: () => void;
    setIsThankYou: Dispatch<SetStateAction<boolean>>;
}) {
    const [feedbackType, setFeedbackType] = useState<'feedback' | 'bug'>('feedback');
    const [rating, setRating] = useState<number>(10);
    const [invalidFormat, setInvalidFormat] = useState<boolean>(false);
    const [invalidSize, setInvalidSize] = useState<boolean>(false);
    const [thoughts, setThoughts] = useState('');
    const [bugDescription, setBugDescription] = useState('');
    const [allowFollowUp, setAllowFollowUp] = useState<'yes' | 'no'>('no');
    const [uploadFile, setUploadFile] = useState<File>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const resetState = () => {
        setFeedbackType('feedback');
        setRating(10);
        setThoughts('');
        setBugDescription('');
        setAllowFollowUp('no');
        setUploadFile(null);
        setInvalidFormat(false);
        setInvalidSize(false);
        setErrorMessage(null);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        setErrorMessage(null);
        try {
            let payload: FeedbackPayload | FormData;

            if (feedbackType === 'feedback') {
                payload = {
                    feedback_type: 'feedback',
                    comment: thoughts,
                    rate: rating,
                    follow_up: allowFollowUp === 'yes',
                };
            } else {
                payload = new FormData();
                payload.append('feedback_type', 'bug');
                payload.append('comment', bugDescription);
                if (uploadFile) payload.append('file', uploadFile);
            }

            const response = await submitFeedback(payload);
            if (response) {
                console.log('Feedback submitted');
                setIsThankYou(true);
                resetState();
                onClose();
            } else {
                setErrorMessage(`Something went wrong while submitting your ${feedbackType}. Please try again later.`);
            }
        } catch (error) {
            setErrorMessage(
                `An error occurred while submitting your ${feedbackType}. Please try again.`
            );
            console.error('Error submitting feedback:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const handleEscKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
                resetState();
            }
        };

        document.addEventListener('keydown', handleEscKey);

        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [onClose]);

    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            if (!['image/jpeg', 'image/png', 'application/pdf', 'video/mp4'].includes(file.type)) {
                setInvalidSize(false);
                setInvalidFormat(true);
                return;
            }
            if (file.size > 1048576) {
                setInvalidFormat(false);
                setInvalidSize(true);
                return;
            }
            setInvalidFormat(false);
            setInvalidSize(false);
            setUploadFile(file);
        }
    };

    const handleClose = () => {
        resetState();
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="modalOverlay">
            <div className="modalContent">
                <div className="modalHeader">
                    <h2 className="modal-what">What would you like to do?</h2>
                    <div className="closeButtons">
                        <EscKey />
                        <button onClick={handleClose} className="closeButton">
                            <CrossIcon />
                        </button>
                    </div>
                </div>
                <div className="line-seperator" />
                <div className="feedbackType">
                    <div className="feedback-label">
                        {feedbackType === 'feedback' ? (
                            <CheckedIcon onClick={() => setFeedbackType('bug')} />
                        ) : (
                            <UnCheckedIcon onClick={() => setFeedbackType('feedback')} />
                        )}
                        <div className="label-text">Give feedback</div>
                    </div>
                    <div className="feedback-label">
                        {feedbackType === 'bug' ? (
                            <CheckedIcon onClick={() => setFeedbackType('feedback')} />
                        ) : (
                            <UnCheckedIcon onClick={() => setFeedbackType('bug')} />
                        )}
                        <div className="label-text">Report a bug</div>
                    </div>
                </div>
                <div className="modalBody">
                    {feedbackType === 'feedback' ? (
                        <>
                            <div className="rating">
                                <p className="body-heading">
                                    <span className="scale-span">On a scale of 1 to 10,</span> how likely are you to
                                    recommend our Leadership Coaching Companion to a colleague?
                                </p>
                                <div className="ratingButtons">
                                    {numbersIcons.map((num, index) => (
                                        <div
                                            key={index}
                                            onClick={() => setRating(index + 1)}
                                            className={`activeRating ${rating === index + 1 ? 'activeOne' : ''}`}
                                        >
                                            <div className="number-icon">{num}</div>
                                        </div>
                                    ))}
                                </div>
                                <div className="ratingLabels">
                                    <span>Not likely at all</span>
                                    <span className="extremely-likely">Extremely likely</span>
                                </div>
                            </div>
                            <div className="line-seperator" />
                            <div className="thoughts">
                                <label htmlFor="thoughts" className="thoughts-text">
                                    Do you have any thoughts you would like to share? (optional)
                                </label>
                                <textarea id="thoughts" value={thoughts} onChange={e => setThoughts(e.target.value)} />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="bugDescription">
                                <label htmlFor="bug-description" className="bug-description">
                                    Describe the bug
                                </label>
                                <textarea
                                    id="bug-description"
                                    value={bugDescription}
                                    onChange={e => setBugDescription(e.target.value)}
                                />
                            </div>
                        </>
                    )}

                    <div className="line-seperator-footer" />
                    <div className="bottom-section">
                        {feedbackType === 'feedback' ? (
                            <div className="followUp">
                                <p>May we follow you up on your feedback?</p>
                                <div className="feedback-lable-container">
                                    <div className="feedback-label">
                                        {allowFollowUp === 'yes' ? (
                                            <CheckedIcon onClick={() => setAllowFollowUp('no')} />
                                        ) : (
                                            <UnCheckedIcon onClick={() => setAllowFollowUp('yes')} />
                                        )}
                                        <div className="label-follow">Yes</div>
                                    </div>
                                    <div className="feedback-label">
                                        {allowFollowUp === 'no' ? (
                                            <CheckedIcon onClick={() => setAllowFollowUp('yes')} />
                                        ) : (
                                            <UnCheckedIcon onClick={() => setAllowFollowUp('no')} />
                                        )}
                                        <div className="label-follow">No</div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="fileUpload">
                                <label htmlFor="file-upload" className="upload-text">
                                    Upload one or more files
                                </label>
                                <div>
                                    <button onClick={handleButtonClick} className="upload-button">
                                        <ClipIcon className="check-icon" />
                                        Select file
                                    </button>

                                    <input
                                        id="file-upload"
                                        type="file"
                                        multiple
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                        accept="image/jpeg, image/png, application/pdf, video/mp4"
                                    />
                                </div>
                                {invalidFormat ? (
                                    <div className="error-msg">
                                        Invalid file format. Only JPG, PNG, PDF, and MP4 are allowed.
                                    </div>
                                ) : invalidSize ? (
                                    <div className="error-msg">File size must be less than 1 MB.</div>
                                ) : (
                                    uploadFile && uploadFile.name && <div>{uploadFile.name}</div>
                                )}
                            </div>
                        )}
                        <div className="bottom-buttons">
                            <button onClick={onClose} className="cancelButton">
                                Cancel
                            </button>
                            <button onClick={handleSubmit} className="sendButton" disabled={isLoading}>
                                <CheckIcon className="check-icon" /> Send
                            </button>
                        </div>
                    </div>
                </div>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
            </div>
        </div>
    );
}
