import React from 'react';
import './Leadership.scss';
import useLeadershipAnimation from '../../../../hooks/useLeadership';
import Companion from '../Companion';
import Approach from '../Approach';
import Expect from '../Expect';

const LeaderShipSection: React.FC = () => {
    const {
        companionFirstRef,
        companionSecondRef,
        companionThirdRef,
        companionSpanRef,
        companionTabletRef,
        textBoxRefs,
        textBoxMobRefs,
        handleCard,
    } = useLeadershipAnimation();

    return (
        <div className="leadership-container">
            <div className="first-container">
                <h2 className="features-title">
                    <div className="feature-subtitle-first">
                        <span className="feature-1">Lead </span>
                        <span className="feature-2">with</span>
                        <span className="features-span">
                            <span className="features-span-1">confidence and ease.</span>
                        </span>
                    </div>
                    <br className="leadership-br" />
                </h2>
                <h2 className="features-title-mobile">
                    <div className="feature-subtitle-first-mob">
                        <span className="feature-1">Lead </span>
                        <span className="feature-2">with</span>
                        <span className="features-span">
                            <span className="features-span-1-mob">confidence and </span>
                        </span>
                        <span className="features-span-2-mob">ease.</span>
                    </div>
                </h2>
            </div>
            <Companion
                companionFirstRef={companionFirstRef}
                companionSecondRef={companionSecondRef}
                companionThirdRef={companionThirdRef}
                companionSpanRef={companionSpanRef}
                companionTabletRef={companionTabletRef}
            />
            <Approach />
            <Expect handleCard={handleCard} textBoxRefs={textBoxRefs} textBoxMobRefs={textBoxMobRefs} />
        </div>
    );
};

export default LeaderShipSection;
