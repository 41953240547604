import React, { Dispatch, SetStateAction, useState } from 'react';
import './MilestoneSessionModal.scss';
import { Session } from '../../../types';
import Icon from '../../../components/Icon';

interface IMilestoneSessionModalProp {
    Session: Session;
    setIsModal: Dispatch<SetStateAction<boolean>>;
}

enum MessageType {
    Problem = 'problem',
    Goal = 'goal',
    Solution = 'solution',
    Action = 'action',
}

export const MilestoneSessionModal = ({ Session, setIsModal }: IMilestoneSessionModalProp) => {
    const [activeTab, setActiveTab] = useState(MessageType.Problem);
    return (
        <div className="session-overview">
            <div className="session-overview-header">
                <MobileSessionTitle activeTab={activeTab} setIsModal={setIsModal} />
            </div>

            <div className="milestone-seperator-black" />
            <div className="milestone-seperator-custom" />

            <div className="session-overview-container">
                <div className="bottom-shadow-body" />
                <div className="session-overview-body">
                    {Session && Session?.[activeTab] && Session?.[activeTab].length > 0 && (
                        <div className="session-overview-body__element">
                            <div className="session-overview-body__block">
                                <div className="session-overview-body__number">
                                    <div className="session-overview-body__ring" />
                                    <div className="element-number">1</div>
                                </div>
                            </div>
                            <div className="element-desc">{Session?.[activeTab]}</div>
                        </div>
                    )}
                </div>
            </div>

            <div className="milestone-seperator-black" />
            <div className="milestone-seperator-custom" />

            <div className="session-overview-footer-container">
                <div className="bottom-shadow-footer" />
                <div className="session-overview-footer">
                    <div className="session-overview-footer__heading" onClick={() => setActiveTab(MessageType.Problem)}>
                        <Icon type="info" className="milestone-mob-icon" />
                        Situation
                    </div>
                    <div className="vertical-container">
                        <div className="vertical-seperator-black" />
                        <div className="vertical-seperator-custom" />
                    </div>
                    <div className="session-overview-footer__heading" onClick={() => setActiveTab(MessageType.Goal)}>
                        <Icon type="star_border" className="milestone-mob-icon" />
                        Session goal
                    </div>
                    <div className="vertical-container">
                        <div className="vertical-seperator-black" />
                        <div className="vertical-seperator-custom" />
                    </div>
                    <div className="session-overview-footer__heading" onClick={() => setActiveTab(MessageType.Solution)}>
                        <Icon type="solution" className="milestone-mob-icon" />
                        Key Enabler
                    </div>
                    <div className="vertical-container">
                        <div className="vertical-seperator-black" />
                        <div className="vertical-seperator-custom" />
                    </div>
                    <div className="session-overview-footer__heading" onClick={() => setActiveTab(MessageType.Action)}>
                        <Icon type="commitments" className="milestone-mob-icon" />
                        Commitments
                    </div>
                </div>
            </div>
        </div>
    );
};

const MobileSessionTitle = ({
    activeTab,
    setIsModal,
}: {
    activeTab: string;
    setIsModal: Dispatch<SetStateAction<boolean>>;
}) => {
    const activeIcon = (tab: string) => {
        switch (tab) {
            case MessageType.Action:
                return 'commitments';
            case MessageType.Problem:
                return 'info';
            case MessageType.Goal:
                return 'star_border';
            case MessageType.Solution:
                return 'solution';
        }
    };

    const activeTitle = (tab: string) => {
        switch (tab) {
            case MessageType.Action:
                return 'Commitments';
            case MessageType.Problem:
                return 'Situation';
            case MessageType.Goal:
                return 'Session goal';
            case MessageType.Solution:
                return 'Key Enabler';
        }
    };
    return (
        <>
            <div className="session-overview-title">
                <Icon type={activeIcon(activeTab)} className="milestone-mob-icon" />
                {activeTitle(activeTab)}
            </div>
            <div onClick={() => setIsModal(false)}>
                <Icon type="cross" className="milestone-mob-icon" />
            </div>
        </>
    );
};
