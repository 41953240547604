import { settingsConstants } from '../constants';

interface AuthState {
    isLeftMenuOpen: boolean
    isRightSidebarOpen: boolean
  }
  
const initialState: AuthState = {
    isLeftMenuOpen: false,
    isRightSidebarOpen: false
};

export function settingsReducer(state = initialState, action: any) {
    switch (action.type) {
        case settingsConstants.SET_IS_LEFT_MENU_OPEN:
            return {
                ...state,
                isLeftMenuOpen: action.data.isLeftMenuOpen,
            };
        case settingsConstants.SET_IS_RIGHT_SIDEBAR_OPEN:
            return {
                ...state,
                isRightSidebarOpen: action.data.isRightSidebarOpen,
            };
        default:
            return state;
    }
}
