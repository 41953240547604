import React from 'react';
import { Milestone, TimelineState } from '../types';
import Icon from '../components/Icon';

export const sessionConstants = {
    SET_MESSAGE: 'SET_MESSAGE',
    SET_SESSIONS: 'SET_SESSIONS',
    SET_ACTIVE_SESSION: 'SET_ACTIVE_SESSION',
    UPDATE_PREV_ACTIVE_SESSION: 'UPDATE_PREV_ACTIVE_SESSION',
    ADD_SESSION: 'ADD_SESSION',
    SET_SOURCES: 'SET_SOURCES',
    SET_MILESTONE: 'SET_MILESTONE',
    SET_LINKS: 'SET_LINKS',
    SET_SESSION_SUMMARY: 'SET_SESSION_SUMMARY',
    SET_ACTIVE_SESSION_OVERVIEW: 'SET_ACTIVE_SESSION_OVERVIEW',
    SET_ACTIVE_SESSION_RESOURCE: 'SET_ACTIVE_SESSION_RESOURCE',
    SET_SESSION_TITLE: 'SET_SESSION_TITLE',
};

export const MILESTONES: Milestone[] = [
    {
        id: 'discovery',
        timelineState: TimelineState.DISCOVERY,
        order: 0,
        iconTimeline: <Icon type="discovery" className="milestone-icon" />,
    },
    {
        id: 'problem',
        label: 'Situation',
        backendVariable: 'problem',
        timelineState: TimelineState.SESSION_GOAL_SETTING,
        order: 1,
        iconTimeline: <Icon type="star_border" className="milestone-icon" />,
        iconCard: <Icon type="info" className="milestone-milestone-icon" />,
    },
    {
        id: 'goal',
        label: 'Session Goal',
        backendVariable: 'goal',
        timelineState: TimelineState.EXPLORING_SOLUTIONS,
        order: 2,
        iconTimeline: <Icon type="explore" className="milestone-icon" />,
        iconCard: <Icon type="star_border" className="milestone-card-icon" />,
    },
    {
        id: 'solution',
        label: 'Key Enabler',
        backendVariable: 'solution',
        timelineState: TimelineState.ACTION_PLANNING,
        order: 3,
        iconTimeline: <Icon type="list" className="milestone-icon" />,
        iconCard: <Icon type="solution" className="milestone-card-icon" />,
    },
    {
        id: 'action',
        label: 'Commitments',
        backendVariable: 'action',
        timelineState: TimelineState.COMMITMENTS,
        order: 4,
        iconTimeline: <Icon type="commitments" className="milestone-icon" />,
        iconCard: <Icon type="commitments" className="milestone-card-icon" />,
    },
];
