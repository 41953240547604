export const textContent = [
    {
        title: "Instant pain relief",
        text: "Your situation is assessed and you receive specific, actionable support",
    },
    {
        title: "Sense of Control",
        text: "You overcome insecurities and gain confidence in your decisions and actions",
    },
    {
        title: "Success experiences",
        text: "You solve problems in your team and achieve better performance",
    },
    {
        title: "Professional growth",
        text: "You begin a guided journey to discover your authentic leadership style",
    },
    {
        title: "Motivation",
        text: "You are consistently supported in developing, expressing, and living your vision",
    },
    {
        title: "Ease",
        text: "You tackle challenges with greater efficiency, freeing up energy for what truly matters to you",
    },
];