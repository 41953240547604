import { Milestone, MilestoneId } from "../types";
import { MILESTONES } from "../constants/session.constants";

export const getMilestoneByBackendVariable = (variable: string): Milestone | undefined => {
    return MILESTONES.find((milestone) => milestone.backendVariable === variable);
};

export const getMilestoneById = (id: MilestoneId): Milestone | undefined => {
    return MILESTONES.find((milestone) => milestone.id === id);
};
