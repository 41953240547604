import axios from 'axios';
import { store } from '../store';
import { FeedbackPayload } from '../types';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.request.use((config) => {
    const state = store.getState();
    const token = state.auth.accessToken;

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    
    return config;
});

export default api;

export const getFaqs = async () => {
      
    const response = await api.get('/api/v1/faqs/');
    if (!response.status || response.status !== 200) {
      throw new Error('Failed to fetch faq questions');
    }
  
    return response.data;
};

export const submitFeedback = async (data: FeedbackPayload | FormData) => {
    try {
        let response;

        if (data instanceof FormData) {
            response = await api.post('/api/v1/bug_feedback/', data);
        } else {
            response = await api.post('/api/v1/feedbacks/', data);
        }

        if (response.status && (response.status === 201 || response.status === 200)) {
            return response.data;
        } else {
            throw new Error('Failed to submit feedback');
        }
    } catch (error) {
        console.error('Error submitting feedback:', error);
        throw error;
    }
};

export const getSessions = async () => {
      
    const response = await api.get('/api/v1/sessions/');
    if (!response.status || response.status !== 200) {
      throw new Error('Failed to fetch sessions');
    }
  
    return response.data;
};

export const getSession = async (hash: string) => {
    const response = await api.get(`/api/v1/sessions/${hash}`);
    if (!response.status || response.status !== 200) {
      throw new Error('Failed to fetch session');
    }

    return response.data;
};

export const getSessionDocuments = async (hash: string) => {
    const response = await api.get(`/api/v1/sessions/${hash}/documents`);
    if (!response.status || response.status !== 200) {
      throw new Error('Failed to fetch session');
    }
    
    return response.data;
};

export const deleteSession = async (hash: string) => {
    const response = await api.delete(`/api/v1/sessions/${hash}/`);
    if (!response.status || response.status !== 204) {
      throw new Error('Failed to delete sessions');
    }

    return response.data;
};

export const uploadFiles = async (hash: string, files) => {
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await api.post(`/api/v1/sessions/${hash}/upload-document`, {
                body: formData,
            });

            if (!response.status || response.status !== 200) {
                const result = response.data;
                console.log("File uploaded successfully:", result);
            } else {
                console.error("File upload failed for", file.name);
            }
        } catch (error) {
            console.error("Error uploading file:", file.name, error);
        }
    }
};

export const getDocument = async (fileName: string) => {
    const response = await api.get(`/api/v1/documents/${fileName}`);
    if (!response.status || response.status !== 200) {
      throw new Error('Failed to fetch document');
    }

    return response.data;
};

export const updateMilestones = async (hash: string, data) => {
    const response = await api.patch(`/api/v1/sessions/${hash}/`, data);
    if (!response.status || response.status !== 200) {
      throw new Error('Failed to update milestones');
    }

    return response.data;
};

export const updatePrivacyPolicy = async () => {
    const response = await api.patch(`/api/v1/users/`, {
        privacy_policy_accepted: true
    });
    if (!response.status || response.status !== 200) {
      throw new Error('Failed to update privacy policy');
    }

    return response;
};

export const getPrivacyPolicy = async () => {
    const response = await api.get(`/api/v1/users/`);
    if (!response.status || response.status !== 200) {
      throw new Error('Failed to update privacy policy');
    }

    return response.data;
};


export const submitSessionRate = async (hash: string, data) => {
    const response = await api.patch(`/api/v1/sessions/${hash}/`, data);
    if (!response.status || response.status !== 200) {
        throw new Error('Failed to submit rate');
    }

    return response.data;
};
