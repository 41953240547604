// Welcome.tsx
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import './Welcome.scss';
import useWindowAnimation from '../../../../hooks/useWindowAnimation';
import {
    EthPattern,
    Hand,
    HandNoise,
    MainLogo,
    MobStraightLine,
    OrigamiBird,
    OrigamiBirdNoise,
    OrigamiBirdNoiseWhite,
    Shadow,
    StraightLine,
} from '../../../../assets/img';
import { sessionActions } from '../../../../actions';
import { history } from '../../../../store';
import { useDispatch } from 'react-redux';

const Welcome = () => {
    const { welcomeRef } = useWindowAnimation();
    const dispatch = useDispatch();

    const handleClick = () => {
        window.open(
            'https://docs.google.com/forms/d/e/1FAIpQLSeUICeqeGBTiZTC6o6JMVkwu9ed_8m2k-i0bYadDUqOM0UFDA/viewform',
            '_blank'
        );
    };

    const startNewSession = () => {
        localStorage.setItem('activeSessionId', '');
        sessionActions.sendMessage(null)(dispatch);
        history.push('/session');
    };

    return (
        <section className="welcome" ref={welcomeRef}>
            <div className="background" />
            <EthPattern className="background-lines" />
            <Shadow className="background-shadow" />
            <div className="linecontainer">
                <img src="/line-vector.png" className="linebackground" alt="line" />
            </div>
            <div className="mobilelinecontainer">
                <MobStraightLine className="mobilelinebackground" />
            </div>
            <div className="handcontainer">
                <Hand className="hand" />
            </div>
            <div className="handcontainer">
                <HandNoise className="handpoints" />
            </div>
            <div className="birdcontainer">
                <OrigamiBird className="bird" style={{ width: '90%' }} />
            </div>
            <div className="birdcontainer">
                <OrigamiBirdNoise className="birdpoints" style={{ width: '90%' }} />
            </div>
            <div className="birdcontainer">
                <OrigamiBirdNoiseWhite className="birdpointswhite" style={{ width: '90%' }} />
            </div>
            <div className="headingcontainer" draggable="false">
                <div className="heading">
                    <div className="title-subheading">LEADERSHIP REIMAGINED AT</div>
                    <MainLogo className="title-logo" />
                </div>
            </div>
            <div className="content" draggable="false">
                <div className="title">
                    <span className="title-your">Scale your</span>
                    <span className="title-leadership">Impact</span>
                </div>

                <p className="title-subtitle">
                    Meet your personal{' '}
                    <span className="title-bold">
                        Leadership <br />
                        Companion
                    </span>{' '}
                    and realise your full potential.
                </p>

                <button className="title-cta" onClick={handleClick}>
                    <FaPlus size={12} /> <div>Register Now</div>
                </button>
            </div>
        </section>
    );
};

export default Welcome;
