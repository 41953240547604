import { MessageInterface, MessageType } from "../types";

export type WebSocketMessage = 
    | { type?: string; content: string; isUser: boolean; finished?: boolean; speechMode?: boolean; timestamp?: string }
    | { type?: string; content: string; isUser: boolean; finished?: true; speechMode?: boolean; timestamp?: string }
    | { type?: MessageType; content: string; timestamp?: string, finished?: true, isUser?: boolean, speechMode?: boolean; };

export function transformMessages(messagesFromBackend: MessageInterface[]): WebSocketMessage[] {


    const transformedMessages: WebSocketMessage[] = [];

    const sortedMessages = [...messagesFromBackend].sort((a, b) => {
        if (a.timestamp < b.timestamp) return -1;
        if (a.timestamp > b.timestamp) return 1;
        return 0;
    });

    for (const message of sortedMessages) {
        if (message.message_type === "chat") {
            transformedMessages.push({
                type: "content",
                content: message.content,
                isUser: true,
                finished: true,
                timestamp: message.timestamp,
            });
    
      
            transformedMessages.push({
                type: "content",
                content: message.reply,
                isUser: false,
                finished: true,
                timestamp: message.timestamp,
            });
        } else {
            transformedMessages.push({
                type: message.message_type,
                content: message.content,
                timestamp: message.timestamp,
            });
        }
    }

    return transformedMessages;
};
