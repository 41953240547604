import { sessionConstants } from '../constants';


const initialState = {
    message: '',
    sessions: [],
    activeSession: null,
    sourcesData: [],
    sessionOverview:false,
    sessionResource:false,
    links: [],
};

export function sessionReducer(state = Object.assign({}, initialState), action: any) {
    switch (action.type) {
        case sessionConstants.SET_MESSAGE:
            return { 
                ...state, 
                message: action.data.message 
            };

        case sessionConstants.SET_SESSIONS:
            return { 
                ...state, 
                sessions: action.data.sessions
            };
        case sessionConstants.SET_ACTIVE_SESSION:
            return {
                ...state,
                activeSession: action.data.session
            };
        case sessionConstants.UPDATE_PREV_ACTIVE_SESSION:
            const updatedSession = action.data.session;
            return {
                ...state,
                sessions: state.sessions.map(session =>
                    session.id === updatedSession.id ? updatedSession : session
                ),
        }
        case sessionConstants.ADD_SESSION:
            return {
                ...state,
                sessions: [...state.sessions, action.data.session],
        }
        case sessionConstants.SET_SOURCES:
            return {
                ...state,
                sourcesData: action.data.sources,
            }
        case sessionConstants.SET_LINKS:
            return {
                ...state,
                links: action.data.links,
            }
        case sessionConstants.SET_ACTIVE_SESSION_OVERVIEW:
            return {
                ...state,
                sessionOverview: action.data.sessionOverview
            };
        case sessionConstants.SET_ACTIVE_SESSION_RESOURCE:
            return {
                ...state,
                sessionResource: action.data.sessionResource
            };
        default:
            return state;
    }
}
