import React from 'react';
import './styles/App.scss';
import { Navigate, Route, Routes } from 'react-router-dom';
import BaseLayout from './containers/BaseLayout';
import Login from "./containers/Login";
import AuthGuard from './components/AuthGuard';
import Dashboard from './containers/Dashboard';
import LandingPage from './containers/LandingContainer';
import Session from './containers/Session';
import gsap from 'gsap';
import { Observer, ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger, Observer)

const App = () => {
    return (
        <>
            <Routes>
                {/* Old version of chat */}
                {/* <Route path="/:message?" index element={<AuthGuard><Home/></AuthGuard>}/> */}
                <Route path="/" element={<Navigate to="/dashboard" replace />}/>
                <Route path="/home" element={<AuthGuard><LandingPage /></AuthGuard>} />
                <Route element={<BaseLayout/>}>
                    <Route path="/login" element={<AuthGuard><Login/></AuthGuard>}/>
                    <Route path="/dashboard" element={<AuthGuard><Dashboard /></AuthGuard>} />
                    <Route path="/session/:hash?" element={<AuthGuard><Session /></AuthGuard>} />
                </Route>
            </Routes>
        </>
    );
};

export default App;
