import React from 'react';
import './GradientLabel.scss';
import Icon from '../../Icon';

interface GradientLabelProps {
    milestoneLabel: string;
}

export const GradientLabel = ({milestoneLabel}: GradientLabelProps ) => {

    return (
        <div className="milestone-label-outer-wrapper">
            <div className="milestone-label-internal-wrapper">
                <Icon type="check_box" className="milestone-icon" />
                <div className="milestone-label">{milestoneLabel}</div>
            </div>
        </div>
    );
};

export default GradientLabel;
