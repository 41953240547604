import React, { useEffect, useState } from 'react';
import './BaseLayout.scss';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LeftMenu from '../LeftMenu';
import RightSidebar from '../RightSidebar';
import { useAppSelector } from '../../store';
import PrivacyPolicyModal from '../../components/PrivacyPolicyModal';
import { getPrivacyPolicy } from '../../utilities/apiService';
import MobLeftMenu from '../LeftMenu/MobLeftMenu';




const BaseLayout = () => {
    const privacyPolicyAcceptedLocal = localStorage.getItem('privacy-policy-accepted');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const session = useAppSelector(state => state.session);
    const isLeftMenuActive = useAppSelector(state => state.setting.isLeftMenuOpen);
    const token = useAppSelector(state => state.auth.accessToken);
    const location = useLocation();

    useEffect(() => {
        const fetchPrivacyPolicy = async () => {
            if (!token) return;
            if (privacyPolicyAcceptedLocal !== 'true') {
                const res = await getPrivacyPolicy();
                if (res.privacy_policy_accepted) {
                    localStorage.setItem('privacy-policy-accepted', 'true');
                    setIsModalOpen(false);
                } else {
                    setIsModalOpen(true);
                }
            } else {
                setIsModalOpen(false);
            }
        };
        fetchPrivacyPolicy();
    }, [privacyPolicyAcceptedLocal, token]);

    return (
        <div className={`eth-base-layout ${isLeftMenuActive ? '' : 'collapsed'}`}>
            <LeftMenu currentPath={location.pathname} />
            <MobLeftMenu currentPath={location.pathname} />
            <div className="eth-base-layout-content w-100 h-100">
                <div className="eth-base-layout-content__background position-relative">
                    <Header />
                    <Outlet />
                    <Footer />
                </div>
            </div>

            {isModalOpen && location.pathname !== '/login' && <PrivacyPolicyModal setIsModalOpen={setIsModalOpen} />}

            <RightSidebar
                session={session?.activeSession}
                sources={session?.sourcesData}
                links={session?.links}
                currentPath={location.pathname}
            />
        </div>
    );
};

export default BaseLayout;
