import React, { useEffect, useState } from 'react';
import './Faqs.scss';
import {
    ActiveFaq,
    BlueTwist,
    DeactiveFaq,
    GradientIcon,
    HeartIcon,
    MissionIcon,
    MobileLock,
    ScienceIcon,
    SecurityIcon,
} from '../../../../assets/img';
import { useGSAP } from '@gsap/react';
import gsap, { ScrollTrigger } from 'gsap/all';
import { qnaData, QnaList } from './QnaBox';
import { getFaqs } from '../../../../utilities/apiService';
import { GuidanceContainer, GuidanceMobContainer } from './GuidanceContainer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

const topGuidanceData = [
    {
        icon: <MobileLock />,
        title: '100% confidential',
        description: 'ETH data security standards are guaranteed',
        mobileRoute: '/lock.png',
    },
    {
        icon: <HeartIcon />,
        title: 'Availabe 24/7',
        description: 'Get the personalized guidance you need, anytime and anywhere',
        mobileRoute: '/heart.png',
    },
];

const guidanceData = [
    {
        icon: <SecurityIcon />,
        title: 'Structured & Flexible',
        description: 'Rely on a solid framework, that adapts to different leadership needs and challenges',
        mobileRoute: '/guard.png',
    },
    {
        icon: <ScienceIcon />,
        title: 'Science-Driven',
        description: 'Powered by proven psychological principles, leadership insights and innovative technology',
        mobileRoute: '/mind.png',
    },
    {
        icon: <MissionIcon />,
        title: 'Mission-Guided',
        description: 'Designed with a human-centric approach, emphasizing growth, empowerment, and ETH values',
        mobileRoute: '/user.png',
    },
];
const FaqsSection = () => {
    const [qnaList, setQnaList] = useState([]);
    const [activeIndex, setActiveIndex] = useState<number>(0);

    useGSAP(() => {
        const tl = gsap.timeline();
        const tl2 = gsap.timeline();
        const mm = gsap.matchMedia();

        mm.add('(min-width:450px)', () => {
            gsap.set('.faq-container', { padding: '0 160px' });

            gsap.set('.faq-background-mob', { display: 'none' });
            gsap.set('.guidance-container-mob', { display: 'none' });
            gsap.set('.qna-container-mob', { display: 'none' });

            gsap.set('.faq-background', { display: 'block' });
            gsap.set('.faq-pattern', { display: 'block' });
            gsap.set('.guidance-container', { display: 'flex' });
            gsap.set('.qna-container', { display: 'grid' });

            tl.fromTo('.faq-background', { opacity: 0 }, { opacity: 1, duration: 1 })
                .fromTo('.faq-pattern', { opacity: 0 }, { opacity: 1, duration: 6 })
                .fromTo('.faq-twist', { opacity: 0 }, { opacity: 1, duration: 6 }, 0)
                .fromTo(
                    '.guidance-1',
                    { y: 200, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1 },
                    1
                )
                .fromTo(
                    '.guidance-span',
                    { y: 200, opacity: 0, display: 'inline-block' },
                    { y: 0, opacity: 1, duration: 1.3 },
                    1
                )
                .fromTo(
                    '.guidance-2',
                    { y: 200, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.6 },
                    1
                )
                .fromTo(
                    '.guidance-3',
                    { y: 200, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.9 },
                    1
                )
                .fromTo(
                    '.guidance-4',
                    { y: 200, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 2.2 },
                    1
                )
                .fromTo(
                    '.guidance-2',
                    { y: 200, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.6 },
                    1
                )
                .fromTo(
                    '.guidance-3',
                    { y: 200, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 1.9 },
                    1
                )
                .fromTo(
                    '.guidance-4',
                    { y: 200, opacity: 0, display: 'inline-block', padding: '0 5px' },
                    { y: 0, opacity: 1, duration: 2.2 },
                    1
                )
                .fromTo(
                    '.guidance-span',
                    { backgroundPosition: '-250px 3px' },
                    { backgroundPosition: '-3px 3px', duration: 6 }
                )
                .fromTo('.guidance-description', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 1 })
                .fromTo('.card-1', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2 })
                .fromTo('.card-2', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2.2 })
                .fromTo('.bottom-card-1', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2.4 })
                .fromTo('.bottom-card-2', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2.6 })
                .fromTo('.bottom-card-3', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2.8 });

            tl2.fromTo('.qna-span', { backgroundPosition: '-250px 3px' }, { backgroundPosition: '-3px 3px', duration: 2 });
            ScrollTrigger.create({
                animation: tl,
                trigger: '.guidance-container',
                toggleActions: 'play reset reverse none',
                start: '-=300 -=300',
                end: '+=300%',
                pin: '.faq-container',
                scrub: true,
            });

            ScrollTrigger.create({
                animation: tl2,
                trigger: '.faq-container',
                toggleActions: 'play reset reverse none',
                start: 'bottom bottom',
                end: '+=100%',
                pin: '.faq-container',
                scrub: true,
            });
        });
        mm.add('(max-width:450px)', () => {
            gsap.set('.faq-container', { padding: '0 16px' });

            gsap.set('.faq-background', { display: 'none' });
            gsap.set('.guidance-container', { display: 'none' });
            gsap.set('.qna-container', { display: 'none' });

            gsap.set('.faq-background-mob', { display: 'block' });
            gsap.set('.guidance-container-mob', { display: 'block' });
            gsap.set('.qna-container-mob', { display: 'flex' });
            gsap.set('.faq-twist-mob', { display: 'block' });
            gsap.set('.faq-points', { display: 'flex' });

            tl.fromTo('.faq-background-mob', { opacity: 0 }, { opacity: 1, duration: 2 })
                .fromTo('.faq-twist-mob', { opacity: 0 }, { opacity: 1, duration: 2 })
                .fromTo('.guidance-title-mob', { opacity: 0 }, { opacity: 1, duration: 2 })
                .fromTo(
                    '.guidance-span-mob',
                    { backgroundPosition: '-250px 8px' },
                    { backgroundPosition: '-2px 8px', duration: 2 }
                )
                .fromTo('.guidance-description-mob', { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 1 })
                .fromTo('.card-1', { y: 100, opacity: 0 }, { y: 0, opacity: 1 })
                .fromTo('.card-2', { y: 100, opacity: 0 }, { y: 0, opacity: 1 })
                .fromTo('.card-3', { y: 100, opacity: 0 }, { y: 0, opacity: 1 })
                .fromTo('.card-4', { y: 100, opacity: 0 }, { y: 0, opacity: 1 })
                .fromTo('.card-5', { y: 100, opacity: 0 }, { y: 0, opacity: 1 })
                .to('.faq-points', { opacity: 1 })
                .fromTo(
                    '.qna-span-mob',
                    { backgroundPosition: '-250px 6px' },
                    { backgroundPosition: '-3px 6px', duration: 2 }
                );
            ScrollTrigger.create({
                animation: tl,
                trigger: '.guidance-container-mob',
                toggleActions: 'play reset reverse none',
                start: 'top top',
                end: 'bottom top',
                pin: '.faq-container',
                scrub: true,
            });
        });
    }, []);

    useEffect(() => {
        const fetchFaq = async () => {
            try {
                const response = await getFaqs();
                if (response) {
                    setQnaList(response);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchFaq();
    }, []);

    return (
        <section className="faq-container">
            <img src="/faq-background.png" className="faq-background" alt="background" />
            <div className="faq-pattern">
                <img src="/faq-pattern.png" alt="background" />
            </div>
            <img src="/blue-twist.png" className="faq-twist" alt="blue-twist" />
            <div className="guidance-container">
                <div className="guidance-top">
                    <div className="guidance-title">
                        <div className="guidance-subtitle-first">
                            <span className="guidance-1">Get the</span>
                            <span className="guidance-span">support</span>
                            <span className="guidance-2">you need,</span>
                            <span className="guidance-2"> right when you need it.</span>
                        </div>
                    </div>
                    <div className="guidance-description">
                        <div className="guidance-details">
                            When you share a situation with the Leadership Companion, it provides a tailored and personalized
                            response. By engaging with it regularly, the support becomes increasingly attuned to your context
                            and needs. You decide when to use your companion, according to how it fits into your daily life
                            and decision-making processes.
                        </div>
                    </div>
                </div>
                <div className="guidance-top-cards">
                    {topGuidanceData.map((card, index) => (
                        <GuidanceContainer
                            icon={card.icon}
                            title={card.title}
                            description={card.description}
                            iconClass={`card-icon`}
                            className={`two-cards card-${index + 1} ${index === 1 ? 'card-heart' : ''}`}
                        />
                    ))}
                </div>
                <div className="guidance-bottom-cards">
                    {guidanceData.map((card, index) => (
                        <GuidanceContainer
                            icon={card.icon}
                            title={card.title}
                            description={card.description}
                            iconClass={`bottom-card-icon-${index + 1}`}
                            className={
                                index === 0
                                    ? `bottom-big-cards two-small-cards bottom-card-${index + 1}`
                                    : index === 1
                                    ? `two-small-cards bottom-small-cards bottom-card-${index + 1}`
                                    : `two-small-cards bottom-small-cards bottom-card-${index + 1}`
                            }
                        />
                    ))}
                </div>
            </div>

            <div className="qna-container">
                <div className="qna-heading">
                    Got <span className="qna-span">questions?</span>
                </div>
                <div className="qna-shadow">
                    <div className="black-gradient">{/* <GradientIcon /> */}</div>
                    <div className="qna-questions">
                        <div className="qna-question-box">
                            <QnaList qnaData={qnaList} />
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile version */}
            <img src="/faq.webp" className="faq-background-mob" alt="background" />
            <img src="/mobile-faq-twist.png" className="faq-twist-mob" alt="blue-twist" />
            <div className="guidance-container-mob">
                <div className="guidance-top-mob">
                    <div className="guidance-title-mob">
                        <div className="guidance-subtitle-first-mob">
                            Get the <span className="guidance-span-mob">support</span> you need, right when you need it.
                        </div>
                        <br className="guidance-br-mob" />
                    </div>
                    <div className="guidance-description-mob">
                        <div className="guidance-details-mob">
                            When you share a situation with the Leadership Companion, it provides a tailored and personalized
                            response. By engaging with it regularly, the support becomes increasingly attuned to your context
                            and needs. You decide when to use your companion, according to how it fits into your daily life
                            and decision-making processes.
                        </div>
                    </div>
                </div>
                <div className="guidance-cards-mob">
                    <Swiper
                        pagination={false}
                        spaceBetween={10}
                        slidesPerView={1}
                        modules={[Pagination]}
                        className="guidance-top-cards-mob"
                        onActiveIndexChange={swiper => setActiveIndex(swiper.activeIndex)}
                    >
                        {[...topGuidanceData, ...guidanceData].map((card, index) => (
                            <SwiperSlide key={index}>
                                <GuidanceMobContainer
                                    key={card.title}
                                    icon={card.mobileRoute}
                                    title={card.title}
                                    description={card.description}
                                    className={`mob-card card-${index + 1}`}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="faq-points">
                        {activeIndex === 0 ? <ActiveFaq /> : <DeactiveFaq />}
                        {activeIndex === 1 ? <ActiveFaq /> : <DeactiveFaq />}
                        {activeIndex === 2 ? <ActiveFaq /> : <DeactiveFaq />}
                        {activeIndex === 3 ? <ActiveFaq /> : <DeactiveFaq />}
                        {activeIndex === 4 ? <ActiveFaq /> : <DeactiveFaq />}
                    </div>
                </div>
            </div>

            <div className="qna-container-mob">
                <div className="qna-heading-mob">
                    Got <span className="qna-span-mob">questions?</span>
                </div>
                <div className="qna-questions-mob">
                    <div className="qna-question-box-mob">
                        <QnaList qnaData={qnaList} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FaqsSection;
